export const SelectedKeys = [
  'asset_name',
  'asset_code',
  'asset_type',
  'class_name',
  'class_code',
  'category_name',
  'category_code',
  'district_name',
  'block_name',
  'gp_name',
  'village_name',
  'dept_name',
  'dept_code',
  'longitude',
  'latitude',
  'altitude',
  'accuracy',
  'pua_code',
  'created_on',
  'uin',
];
