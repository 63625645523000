import React from 'react';
import Carousel from './Carousel';
import './footer.css';

const Footer = () => {
  var currentYear = new Date().getFullYear();
  return (
    <>
      <section className='py-5 bg-grey border-bottom'>
        <div className='container'>
          <h4 className='text-center title-decoration'>DEPARTMENTS</h4>
          <Carousel />
        </div>
      </section>

      {/* Footer Section */}

      <footer className='fixed-bottom'>
        <div
          className='container-fluid bg-grey small'
          style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <div className='row'>
            <div className='col-md-6'>
              <p className='m-0 p-3'>
                ©{currentYear} Finance Department Govt. of Odisha. | Powered by
                ORSAC
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
