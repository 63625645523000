import React, { useState } from 'react';

export const TokenContext = React.createContext();

const TokenContextProvider = ({ children }) => {
  const [token, setToken] = useState('' || localStorage.getItem('jwt_token'));

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenContextProvider;
