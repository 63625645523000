import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

export const ChangePasswordForm = (props) => {
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    watch,
    onClickChangePasswordHandler,
  } = props;

  // Current password
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordShown(currentPasswordShown ? false : true);
  };

  // New password
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };
  // Confirm password
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  return (
    <Form onSubmit={handleSubmit(onClickChangePasswordHandler)}>
      <Form.Group
        className='mb-3 pass-wrapper'
        controlId='formPlaintextPassword'
      >
        <Form.Label>
          Current Password
          <span className='text-danger'> *</span>
        </Form.Label>

        <Form.Control
          type={currentPasswordShown ? 'text' : 'password'}
          name='oldPassword'
          {...register('oldPassword', {
            required: 'Current Password Required!',
          })}
        />

        {errors.oldPassword && (
          <small className='text-danger'>{errors.oldPassword.message}</small>
        )}
        {currentPasswordShown ? (
          <BsFillEyeFill
            className='eye-react-icons'
            onClick={toggleCurrentPasswordVisibility}
            size={20}
            fill={'#808080'}
          />
        ) : (
          <BsFillEyeSlashFill
            className='eye-react-icons'
            onClick={toggleCurrentPasswordVisibility}
            size={20}
            fill={'#808080'}
          />
        )}
      </Form.Group>

      <div style={{ color: 'gray' }}>
        <ul>
          <li>
            Only Alphanumeric characters{' '}
            <span className=' font-weight-bold'>(e.g.A-Z, a-z, 0-9)</span> are
            allowed!
          </li>{' '}
          <li>
            Only these{' '}
            <span className='text-uppercase font-weight-bold'>(_.@#$*!)</span>{' '}
            special characters are allowed!
          </li>
          <li>Your password can't be similar to your current password!</li>{' '}
        </ul>
      </div>

      <Form.Group
        className='mb-3 pass-wrapper'
        controlId='formPlaintextPassword'
      >
        <Form.Label>
          New Password <span className='text-danger'> *</span>
        </Form.Label>

        <Form.Control
          type={newPasswordShown ? 'text' : 'password'}
          name='newPassword'
          {...register('newPassword', {
            required: 'New Password Required!',
            pattern: {
              value: /^[ A-Za-z0-9_@._@#$*!]*$/,
              message: 'Password should meet the above criteria!',
            },
            // validate: (value) =>
            //   value === watchOldPassword &&
            //   'New Password could not be old password!',
          })}
        />
        {errors.newPassword && (
          <small className='text-danger'>{errors.newPassword.message}</small>
        )}

        {newPasswordShown ? (
          <BsFillEyeFill
            className='eye-react-icons'
            onClick={toggleNewPasswordVisibility}
            size={20}
            fill={'#808080'}
          />
        ) : (
          <BsFillEyeSlashFill
            className='eye-react-icons'
            onClick={toggleNewPasswordVisibility}
            size={20}
            fill={'#808080'}
          />
        )}
      </Form.Group>
      <Form.Group
        className='mb-3 pass-wrapper'
        controlId='formPlaintextPassword'
      >
        <Form.Label>
          Confirm Password <span className='text-danger'> *</span>
        </Form.Label>
        <Form.Control
          type={confirmPasswordShown ? 'text' : 'password'}
          name='confirmPassword'
          {...register('confirmPassword', {
            required: 'Confirm Password Required!',
            validate: (value) =>
              value === getValues('newPassword') ||
              'The passwords do not match',
          })}
        />
        {errors.confirmPassword && (
          <small className='text-danger'>
            {errors.confirmPassword.message}
          </small>
        )}

        {confirmPasswordShown ? (
          <BsFillEyeFill
            className='eye-react-icons'
            onClick={toggleConfirmPasswordVisibility}
            size={20}
            fill={'#808080'}
          />
        ) : (
          <BsFillEyeSlashFill
            className='eye-react-icons'
            onClick={toggleConfirmPasswordVisibility}
            size={20}
            fill={'#808080'}
          />
        )}
      </Form.Group>
    </Form>
  );
};
