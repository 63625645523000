import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { VscClose } from 'react-icons/vsc';
import { Card, Tab, Tabs } from 'react-bootstrap';
import SampleFilter from './SampleFilter';
import AdminFilter from './AdminFilter';
import ListOfLayers from './ListOfLayers';
import ListOfLegends from './ListOfLegends';
import { cardWidthAtom } from '../../recoil/atom/GIS Dashboard/cardWidth.atom';
import { cardHeadingTextAtom } from '../../recoil/atom/GIS Dashboard/CardHeaderText.atom';
import { assetCountOnFilterAtom } from '../../recoil/atom/GIS Dashboard/assetCountOnFilter.atom';
import { assetInformationForPlotAtom } from '../../recoil/atom/GIS Dashboard/assetInformationForPlot.atom';
import { SelectedKeys } from './selectedKeysForDisplay';
import './style.css';
import { MapFilter } from '../../services/GIS Dashboard/mapFilter';
import { loginTokenAtom } from '../../recoil/atom/loginTokenAtom';

const CollapseCard = (props) => {
  const { handleCloseCollapse, cardHeadingStyle, name } = props;
  const [token] = useRecoilState(loginTokenAtom);

  var actualJsonData = {
    type: 'FeatureCollection',
    features: [],
  };

  const userData = JSON.parse(localStorage.getItem('user'));
  const [selectedLayer, setSelectedLayer] = useState([4]);

  const [, setAssetCountOnFilter] = useRecoilState(assetCountOnFilterAtom);
  const [, setAssetInfoForPlotting] = useRecoilState(
    assetInformationForPlotAtom
  );

  const [cardWidth] = useRecoilState(cardWidthAtom);
  const [headerText] = useRecoilState(cardHeadingTextAtom);

  const jsonForBoundaries = [
    {
      id: 4,
      boundary_name: 'District',
    },
    {
      id: 5,
      boundary_name: 'Block',
    },
    {
      id: 6,
      boundary_name: 'GP',
    },
    {
      id: 7,
      boundary_name: 'Village',
    },
  ];

  const defaultValue = {
    district_id: 0,
    block_id: 0,
    gp_id: 0,
    village_id: 0,
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues: defaultValue });

  const setVisibility = async (id) => {
    let visibility = name.getLayers().array_[id].values_.visible;
    visibility && id !== 0
      ? (name.getLayers().array_[id].values_.visible = false)
      : (name.getLayers().array_[id].values_.visible = true);
    name.render();
  };

  // on click boundary handler
  const onClickBoundary = (e, id) => {
    e.preventDefault();
    // check if boundary already selected or not if not selected push the id into the array otherwise pop out from array
    if (selectedLayer.includes(id)) {
      const ids = selectedLayer.filter((si) => si !== id);
      setSelectedLayer(ids);
    } else setSelectedLayer((prevState) => [...prevState, id]);
    setVisibility(id);
  };

  // filter on map
  const onSubmitFilter = (data) => {
    let formData = new FormData();
    formData.append('user_id', userData.user_id);
    formData.append('geo_flag', 1);

    assetInformationObjectCreation();

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        var val = data[key];
        formData.append(key, val);
      }
    }

    // FIXME: uncomment this code
    // call api for geom
    MapFilter(token, formData)
      .then((response) => {
        if (response && response?.status == 1) {
          setAssetCountOnFilter(response?.data?.totalItems);
          assetInformationObjectCreation(response?.data?.assetList);
        } else {
          setAssetCountOnFilter([]);
          setAssetInfoForPlotting([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setAssetCountOnFilter([]);
        setAssetInfoForPlotting([]);
      });
  };

  const assetInformationObjectCreation = () => {
    var data = [
      {
        asset_id: 1867,
        asset_name: 'New Asset 2',
        asset_code: '223424',
        asset_type: 1,
        class_id: 9,
        class_name: 'Intangible Assets',
        class_code: '09',
        category_id: 78,
        category_name: 'Mouse',
        category_code: '002',
        district_id: 20,
        district_name: 'Khurda',
        block_id: 53,
        block_name: 'Bhubaneswar',
        gp_id: 1223,
        gp_name: null,
        village_id: 54881,
        village_name: null,
        pc_id: 6,
        ac_id: 31,
        dept_id: 11,
        dept_name: 'Finance',
        dept_code: 'FIN',
        created_on: '24/02/2022',
        longitude: 85.8105830792,
        latitude: 20.3073245866,
        altitude: 31.7919998169,
        accuracy: 14.3113708496,
        incharge_name: null,
        incharge_phone: 0,
        incharge_image: null,
        incharge_designation: null,
        surveyor_id: null,
        surveyor_image: null,
        uin: 'FIN090021',
        approve_status: 1,
        is_active: true,
        created_by: null,
        captured_date: '2022-02-24 13:03:28.641',
        assetStatus: 1,
        assetStatusName: 'ACTIVE',
        disposalId: 0,
        approveHLevel: 0,
        pua_code: '05-2075-00-800-2897-91095-000-1-11-0-1',
        geoTagged: null,
        geom: '{"type":"Point","coordinates":[87.810583079,20.307324587]}',
        editable: false,
      },
      {
        asset_id: 1866,
        asset_name: 'Mac',
        asset_code: '099',
        asset_type: 1,
        class_id: 9,
        class_name: 'Intangible Assets',
        class_code: '09',
        category_id: 71,
        category_name: 'Training Software',
        category_code: '001',
        district_id: 20,
        district_name: 'Khurda',
        block_id: 53,
        block_name: 'Bhubaneswar',
        gp_id: 1223,
        gp_name: null,
        village_id: 54881,
        village_name: null,
        pc_id: 6,
        ac_id: 31,
        dept_id: 10,
        dept_name: 'Fisheries & Animal Resources Development',
        dept_code: 'FAR',
        created_on: '24/02/2022',
        longitude: 85.811066455,
        latitude: 20.3076371,
        altitude: 3.9392147064,
        accuracy: 6.0708007813,
        incharge_name: null,
        incharge_phone: 0,
        incharge_image: null,
        incharge_designation: null,
        surveyor_id: null,
        surveyor_image: null,
        uin: 'FAR090011',
        approve_status: 1,
        is_active: true,
        created_by: null,
        captured_date: '2022-02-24 10:44:15.176',
        assetStatus: 1,
        assetStatusName: 'ACTIVE',
        disposalId: 0,
        approveHLevel: 0,
        pua_code: '07-2230-03-102-0126-40008-000-1-11-0-1',
        geoTagged: null,
        geom: '{"type":"Point","coordinates":[86.21,20.34794]}',
        editable: false,
      },
    ];

    // set the data according to the required style
    // loop the array data
    for (let index = 0; index < data.length; index++) {
      let firstInnerJson = {};
      let propertiesJson = {};
      // loop the individual json
      for (var key in data[index]) {
        if (data[index].hasOwnProperty(key)) {
          let val = data[index][key];
          // loop the selected key array and insert the value into the object
          SelectedKeys.forEach((item) => {
            if (item === key) propertiesJson[key] = val;
          });
          // properties set
          firstInnerJson['properties'] = propertiesJson;

          if (key === 'geom') {
            firstInnerJson['geometry'] = JSON.parse(val);
          }
          firstInnerJson['type'] = 'Feature';
        }
      }
      actualJsonData.features.push(firstInnerJson);
    }

    setAssetInfoForPlotting(actualJsonData);
  };

  const onClickResetHandler = (e) => {
    e.preventDefault();
    reset();
    setAssetCountOnFilter(0);
  };

  const propsForAdminFilter = {
    register: register,
    errors: errors,
    handleSubmit: handleSubmit,
    getValues: getValues,
    onSubmitFilter: onSubmitFilter,
    onClickResetHandler: onClickResetHandler,
  };

  const propsForListOfLayers = {
    jsonForBoundaries,
    onClickBoundary,
    selectedLayer,
  };

  return (
    <>
      <Card
        style={{ width: `${cardWidth}rem`, borderRadius: '5px' }}
        border='light'
      >
        <Card.Header className='d-flex justify-content-between'>
          <span style={cardHeadingStyle}>{headerText}</span>
          <button
            type='button'
            className='close'
            aria-label='Close'
            onClick={(e) => handleCloseCollapse(e)}
          >
            <VscClose size={19} />
          </button>
        </Card.Header>

        {headerText === 'Legends' && <ListOfLegends />}

        {/* Filter section */}
        {headerText === 'Filter' && (
          <div className='p-3 '>
            <Tabs
              id='uncontrolled-tab-example'
              className='mb-3'
              variant='pills'
              style={{ backgroundColor: '#f1f2f5' }}
            >
              <Tab
                eventKey='sample'
                title='Sample'
                className='p-3'
                tabClassName='active-tab'
              >
                <SampleFilter {...propsForAdminFilter} />
              </Tab>
              <Tab eventKey='admin' title='Admin' className='p-3'>
                <AdminFilter {...propsForAdminFilter} />
              </Tab>
            </Tabs>
          </div>
        )}

        {/* Layers section */}
        {headerText === 'Layers' && <ListOfLayers {...propsForListOfLayers} />}
      </Card>
    </>
  );
};

export default CollapseCard;
