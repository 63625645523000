import React from 'react';
import { Form, Button } from 'react-bootstrap';

const SendOtpForm = (props) => {
  const {
    forgotPasswordRef,
    userNameRef,
    userEmailRef,
    onClickSendOTPButtonHandler,
    onClickCancelButtonHandler,
  } = props;
  return (
    <div>
      <Form style={{ display: 'none' }} ref={forgotPasswordRef}>
        <div className='mb-4'>
          <Form.Group>
            <Form.Control
              type='text'
              placeholder='User ID'
              name='username'
              ref={userNameRef}
            />
          </Form.Group>
        </div>
        <div className='mb-4'>
          <Form.Group>
            <Form.Control
              type='email'
              placeholder='Email id'
              name='email'
              ref={userEmailRef}
            />
          </Form.Group>
        </div>
        <div className='d-flex align-content-center'>
          <Button
            className='px-4 btn login-button'
            onClick={(e) => onClickSendOTPButtonHandler(e)}
          >
            Send OTP
          </Button>

          <Button
            className='px-4 btn cancel-button ms-3'
            onClick={(event) => onClickCancelButtonHandler(event)}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SendOtpForm;
