import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../CustomButton';
import './CustomModalStyle.css';

const index = ({
  show,
  handleClose,
  size,
  modalTitleText,
  children,
  handleSubmit,
  customButtonStyle,
  customButtonText,
  dialogWidth,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size={size}
        dialogClassName={`modal-${dialogWidth}w`}
      >
        <Modal.Header closeButton className='modal-header-section'>
          <Modal.Title style={{ fontSize: '16px' }}>
            {modalTitleText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body scrollable={true}>{children}</Modal.Body>
        <Modal.Footer>
          <div className='text-center w-100'>
            <CustomButton style={customButtonStyle} handleClick={handleSubmit}>
              {customButtonText}
            </CustomButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default index;
