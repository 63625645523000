import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import SimpleReactLightbox from 'simple-react-lightbox';
import Routes from './routes';
import './assets/font/icons.eot';
import './assets/font/icons.svg';
import './assets/font/icons.ttf';
import './assets/font/icons.woff';
import 'react-multi-carousel/lib/styles.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'ol/ol.css';
import 'react-tippy/dist/tippy.css';
import './index.css';

ReactDOM.render(
  <SimpleReactLightbox>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </SimpleReactLightbox>,
  document.getElementById('root')
);
