export const zipcodeValidator = (pincode) => {
  var pincodeLength = pincode.toString().length;
  if (pincode != 0 && pincodeLength != 6 && !isNaN(pincode)) {
    return false;
  } else if (pincode.toString().indexOf(0) === 0) {
    return false;
  } else {
    return true;
  }
};
