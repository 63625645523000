import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { MdOutlineZoomIn } from 'react-icons/md';
import { terrainImageUrl, roadImageUrl } from '../../backend';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { BsCaretLeft, BsCaretRight } from 'react-icons/bs';
import { GLobe, PopupRoadMap } from '../../assets/images';

const Popup = (props) => {
  const { popupData, onClosePopupHandler, handleZoomHandler, onClickImage } =
    props;

  return (
    <div className='p-0 popup-card-shadow'>
      <Card border='light'>
        <Card.Header className='card-header-popup map-popup__footer'>
          <Row className='p-0 m-0 justify-content-between w-100'>
            <Col xs={8} className='m-0 p-0'>
              <div className='map-popup__inline-actions-container'>
                {/* Zoom control */}
                <div className='map-popup-button' onClick={handleZoomHandler}>
                  <MdOutlineZoomIn size={19} style={{ margin: '0 3px' }} />
                  <span className='popup-action-text'>Zoom to</span>
                </div>

                <div
                  className='map-popup-button'
                  onClick={() => onClickImage(1)(terrainImageUrl)('Terrain')}
                >
                  <img
                    src={GLobe}
                    alt='globe'
                    height={15}
                    style={{ margin: '1px 3px' }}
                  />

                  <span className='popup-action-text'>Imagery</span>
                </div>

                <div
                  className='map-popup-button'
                  onClick={() => onClickImage(2)(roadImageUrl)('Road')}
                >
                  <img
                    src={PopupRoadMap}
                    alt='Popup Road Map'
                    height={15}
                    style={{ margin: '1px 3px', borderRadius: '12px' }}
                  />

                  <span className='popup-action-text'>Streets</span>
                </div>
              </div>
            </Col>

            <Col xs={4} className='ms-0 ps-0'>
              {/* Accordion */}
              <div
                className='map_popup__navigation justify-content-end'
                style={{ height: '35px', marginTop: '5px' }}
              >
                <div className='map-popup-button'>
                  <BsCaretLeft size={22} />
                </div>

                <span className='popup-action-text'>1 of 5</span>
                <div className='map-popup-button'>
                  <BsCaretRight size={22} />
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className='m-0 p-0'>
          <Card.Title className='map-popup-header'>
            <div>
              <h2 className='map-popup-heading-title'>MMDR: SWMH-2106</h2>
            </div>
            <div className='popup-header-buttons'>
              <div
                className='map-popup-button'
                onClick={(e) => onClosePopupHandler(e)}
              >
                <IoCloseOutline size={20} />
              </div>
            </div>
          </Card.Title>
          <article className='popup__content '>
            <div>
              <div className='popup-widget'>
                <div className='popup-feature__content-element'>
                  <Table
                    striped
                    borderless
                    className='popup-widget__table'
                    size='lg'
                  >
                    <tbody className='c1'>
                      {popupData.length > 0 &&
                        popupData.map((data) => {
                          return (
                            <tr>
                              <th>{data?.nameValue}</th>
                              <td>{data?.value}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </article>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Popup;
