import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isValidate from '../utilities/storage.service';

const PrivateRoute = ({ component: Component, ...rest }) => (
  /* Show the component, when the user is logged in 
     Otherwise, redirect the user to Signup Page.   */
  <Route
    {...rest}
    render={(props) =>
      isValidate() ? <Component {...props} /> : <Redirect to='/login' />
    }
  />
);

export default PrivateRoute;
