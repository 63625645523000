import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../CustomButton';

const CustomAlert = ({ showModal, setShowModal, handleClick , buttonText, headingText}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby='example-modal-sizes-title-sm'
      >
        <Modal.Header closeButton className='modal-header-section'>
          <Modal.Title
            id='example-modal-sizes-title-sm'
            style={{ fontSize: '19px' }}
          >
            {headingText}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>{`Are you sure to ${headingText.toLowerCase()}?`}</div>
          <div style={{ textAlign: 'end' }}>
            <CustomButton
              handleClick={() => setShowModal(false)}
              style={{
                backgroundColor: '#fefefe94',
                marginRight: '25px',
                width: '22%',
                color: '#060f1b',
                borderColor: '#b3afac',
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              handleClick={handleClick}
              style={{
                backgroundColor: 'rgb(171 74 28)',
                width: '22%',
                borderColor: 'rgb(171 74 28)',
              }}
            >
              {buttonText}
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomAlert;
