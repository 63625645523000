import React, { useState } from 'react';

export const AssetInfoContext = React.createContext();

const AssetInfoContextProvider = ({ children }) => {
  const [assetDataFromContext, setAssetDataFromContext] = useState([]);

  return (
    <AssetInfoContext.Provider
      value={{ assetDataFromContext, setAssetDataFromContext }}
    >
      {children}
    </AssetInfoContext.Provider>
  );
};

export default AssetInfoContextProvider;
