import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useLightbox, SRLWrapper } from 'simple-react-lightbox';
import { BsDownload, BsCardText } from 'react-icons/bs';

import {
  OpamsInfo,
  Op01th,
  Op01,
  Op02,
  Op03,
  Op04,
  Op05,
  Op06,
  Op07,
  Op08,
  Op09,
  Op10,
} from '../../assets/images';


// PDF File
import OPAMSPDF from '../../assets/files/Odisha-Public-Asset-Management-System.pdf';
import OPAMSGASAB from '../../assets/files/OPAMS-GASAB-Guideline.pdf';

const About = () => {
  const { openLightbox } = useLightbox();

  // Lightbox option
  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },

    caption: {
      captionColor: '#a6cfa5',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '300',
      captionTextTransform: 'uppercase',
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <>
      <section className='my-5' id='about'>
        <Container>
          <Row>
            <Col xs={12} md={5}>
              <div className='about-header-hide'>
                <h5 className='about-header'>
                  <strong>About</strong>
                </h5>
                <h3 className='odisha-public-header'>
                  Odisha Public Asset Management System
                </h3>
                <hr />
              </div>
              <Row className='ps-2 pe-2 '>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='d-flex flex-row align-center justify-content-center'>
                    <a
                      className='btn  about-button open-ppt me-2'
                      onClick={() => openLightbox()}
                      href
                    >
                      <BsCardText />
                      &nbsp;&nbsp; About OPAMS
                    </a>
                  </div>

                  <div className='d-flex flex-row align-center justify-content-center'>
                    <a
                      className='btn about-button me-2'
                      href={OPAMSPDF}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <BsDownload />
                      &nbsp;&nbsp; Download
                    </a>
                  </div>

                  <div className='d-flex flex-row align-center justify-content-center'>
                    <a
                      className='btn about-button'
                      href={OPAMSGASAB}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <BsDownload />
                      &nbsp;&nbsp; GASAB Guideline
                    </a>
                  </div>
                </div>

                <Row>
                  <Container fluid className='pe-0'>
                    <img
                      className='mt-3 img-thumbnail'
                      src={OpamsInfo}
                      alt='opams'
                    />
                  </Container>
                </Row>
              </Row>
            </Col>

            <Col xs>
              <div className='ppt-first-img-about'>
                <h5 className='about-header'>
                  <strong>About</strong>
                </h5>
                <h3>Odisha Public Asset Management System</h3>
              </div>
              <hr />
              <img
                className='img-fluid border mb-2 '
                src={Op01}
                alt='ppt-page01'
              />
            </Col>
          </Row>

          <div className='d-none hiddenppt'>
            <SRLWrapper options={options}>
              <a href={Op01}>
                <img src={Op01th} className='img-thumbnail' alt='Page - 01' />
              </a>
              <a href={Op02}>
                <img src={Op02} className='img-thumbnail' alt='Page - 02' />
              </a>
              <a href={Op03}>
                <img src={Op03} className='img-thumbnail' alt='Page - 03' />
              </a>
              <a href={Op04}>
                <img src={Op04} className='img-thumbnail' alt='Page - 04' />
              </a>
              <a href={Op05}>
                <img src={Op05} className='img-thumbnail' alt='Page - 05' />
              </a>
              <a href={Op06}>
                <img src={Op06} className='img-thumbnail' alt='Page - 06' />
              </a>
              <a href={Op07}>
                <img src={Op07} className='img-thumbnail' alt='Page - 07' />
              </a>
              <a href={Op08}>
                <img src={Op08} className='img-thumbnail' alt='Page - 08' />
              </a>
              <a href={Op09}>
                <img src={Op09} className='img-thumbnail' alt='Page - 09' />
              </a>
              <a href={Op10}>
                <img src={Op10} className='img-thumbnail' alt='Page - 10' />
              </a>
            </SRLWrapper>
          </div>
        </Container>
      </section>
    </>
  );
};

export default About;
