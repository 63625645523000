import React from 'react';
import { Form } from 'react-bootstrap';
import { CustomModal } from '../index';

const index = (props) => {
  const {
    register,
    errors,
    handleSubmit,
    showModalForPassVerification,
    setShowModalForPassVerification,
    submitButtonStyle,
    confirmPasswordModalSize,
    modalHeaderText,
    customButtonText,
    onClickHandler,
  } = props;

  return (
    <>
      <CustomModal
        show={showModalForPassVerification}
        handleClose={setShowModalForPassVerification}
        modalTitleText={modalHeaderText}
        handleSubmit={handleSubmit(onClickHandler)}
        size={confirmPasswordModalSize}
        customButtonStyle={submitButtonStyle}
        customButtonText={customButtonText}
        dialogWidth={30}
      >
        <div>
          <Form onSubmit={handleSubmit(onClickHandler)}>
            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>
                Password <span className='text-danger'> *</span>
              </Form.Label>
              <Form.Control
                type='password'
                {...register('password', {
                  required: 'Password Required!',
                })}
              />
              {errors.password && (
                <small className='text-danger'>{errors.password.message}</small>
              )}
            </Form.Group>
          </Form>
        </div>
      </CustomModal>
    </>
  );
};

export default index;
