import axios from 'axios';
import { baseURL } from '../backend';

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Request-Method': 'POST',
    Accept: 'application/json',
  },
});

export default axiosInstance;

export const axiosConfig = (token) => {
  let object = {
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Request-Method': 'POST GET PUT',
      Accept: 'application/json',
    },
  };
  return object;
};

// axios Config for pdf download
export const axiosConfigPdf = (token) => {
  let object = {
    baseURL: baseURL,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return object;
};
