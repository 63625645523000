import React, { useState, useEffect } from 'react';
import { ProgressBar, Spinner } from 'react-bootstrap';
import './style.css';
const SuspenseFallback = () => {
  const [countOfProgess, setCountOfProgess] = useState(0);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCountOfProgess((oldProgress) => {
  //       if (100 == oldProgress) return 0;
  //       return Math.min(oldProgress + Math.random() * 10, 100);
  //     });
  //   }, 150);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  return (
    <div
      className='d-flex justify-content-center align-items-center flex-column'
      style={{ height: '100vh' }}
    >
      <button class='btn-spinner'>
        <span class='spinner'></span>
        <span class='btn__text'>Loading...</span>
      </button>
      {/* <div className='d-flex flex-row'>
        <Spinner animation='border' role='status' variant='secondary'></Spinner>
      </div>
      <div className='mt-3 text-muted display-6' style={{ fontSize: '22px' }}>
        Loading...
      </div> */}
    </div>
  );
};

export default SuspenseFallback;
