import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import * as olSource from 'ol/source';
import BingMaps from 'ol/source/BingMaps';
import { Image as ImageLayer } from 'ol/layer';
import { Attribution, defaults as defaultControls } from 'ol/control';
import {
  assetLayerGeoServerURL,
  googleRoadMapURL,
  googleSatelliteURL,
} from '../../../backend';

export const osm = new TileLayer({
  source: new OSM({
    attributions: [
      `Copyright @ ${new Date().getFullYear()} ORSAC | All rights reserved`,
    ],
  }),
});

// District Boundary
export const odishaDistrictBoundary = new ImageLayer({
  title: 'District Boundary',
  source: new olSource.ImageWMS({
    url: assetLayerGeoServerURL,
    params: {
      LAYERS: 'OPAMS:odisha_district_boundary',
      FORMAT: 'image/png',
      TILED: true,
      VERSION: '1.3.0',
    },
  }),
  visible: true,
  crossOrigin: 'anonymous',
});

// Block Boundary
export const odishaBlockBoundary = new ImageLayer({
  title: 'Block Boundary',
  source: new olSource.ImageWMS({
    url: assetLayerGeoServerURL,
    params: {
      LAYERS: 'OPAMS:odisha_block_boundary_2021',
      FORMAT: 'image/png',
      TILED: true,
      VERSION: '1.3.0',
    },
  }),
  visible: false,
  crossOrigin: 'anonymous',
});

// GP Boundary
export const odishaGrampanchayatBoundary = new ImageLayer({
  title: 'Gram Panchayat Boundary',
  source: new olSource.ImageWMS({
    url: assetLayerGeoServerURL,
    params: {
      LAYERS: 'OPAMS:odisha_grampanchayat_boundary_2021',
      FORMAT: 'image/png',
      TILED: true,
      VERSION: '1.3.0',
    },
  }),
  visible: false,
  crossOrigin: 'anonymous',
});

// Village boundary
export const odishaRevenueVillageBoundary = new ImageLayer({
  title: 'Village Boundary',
  source: new olSource.ImageWMS({
    url: assetLayerGeoServerURL,
    params: {
      LAYERS: 'OPAMS:odisha_revenue_village_boundary_2021',
      FORMAT: 'image/png',
      TILED: true,
      VERSION: '1.3.0',
    },
  }),
  visible: false,
  crossOrigin: 'anonymous',
});

// Satellite Layer
export const googleLayerSatellite = new TileLayer({
  title: 'Google Satellite',
  name: 'Google satellite',
  type: 'base',
  source: new olSource.TileImage({
    url: googleSatelliteURL,
    attributions: [
      `Copyright @ ${new Date().getFullYear()} ORSAC | All rights reserved`,
    ],
  }),
  visible: false,
});

// Road Layer
export const googleLayerRoadMap = new TileLayer({
  title: 'Google Road Map',
  name: 'Google Road Map',
  type: 'base',
  source: new olSource.TileImage({
    url: googleRoadMapURL,
    attributions: [
      `Copyright @ ${new Date().getFullYear()} ORSAC | All rights reserved`,
    ],
  }),
  visible: false,
});

// Bing Map Layer
export const bingMap = new TileLayer({
  type: 'base',
  title: 'Bing Map',
  source: new BingMaps({
    imagerySet: 'RoadOnDemand',
    key: process.env.REACT_APP_BING_MAP_API_KEY,
    attributions: [
      `Copyright @ ${new Date().getFullYear()} ORSAC | All rights reserved`,
    ],
  }),
  visible: false,
});

// bing map :- AerialWithLabelsOnDemand