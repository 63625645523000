import React from 'react';
import Dept1 from '../../assets/images/dept/dept-1.jpg';
import Dept2 from '../../assets/images/dept/dept-2.jpg';
import Dept3 from '../../assets/images/dept/dept-3.jpg';
import Dept4 from '../../assets/images/dept/dept-4.jpg';
import Dept5 from '../../assets/images/dept/dept-5.jpg';
import Dept6 from '../../assets/images/dept/dept-6.jpg';
import Dept7 from '../../assets/images/dept/dept-7.jpg';
import Dept8 from '../../assets/images/dept/dept-8.jpg';
import Dept9 from '../../assets/images/dept/dept-9.jpg';
import Dept10 from '../../assets/images/dept/dept-10.jpg';
import Dept11 from '../../assets/images/dept/dept-11.jpg';

import Carousel from 'react-multi-carousel';

const CarouselItems = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Carousel
        responsive={responsive}
        arrows={false}
        infinite={true}
        centerMode={true}
        autoPlay={true}
        autoPlaySpeed={2000}
      >
        <div className='carousel-items'>
          <a
            href='http://agriodisha.nic.in/Http_public/index.aspx'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept1} alt='agri-odisha' />
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.fardodisha.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept2} alt='fard-odisha' />
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.foododisha.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept3} alt='food-odisha' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.urbanodisha.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept4} alt='urban-odisha' />
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://odishapanchayat.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept5} alt='odisha-panchayat' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.rdodisha.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept6} alt='rd-odisha' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.odisha.gov.in/schooleducation/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept7} alt='school-education' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.stscodisha.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept8} alt='stsc-odisha' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.dowrorissa.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept9} alt='dowr-orissa' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://wcdodisha.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept10} alt='wcd-odisha' />{' '}
          </a>
        </div>
        <div className='carousel-items'>
          <a
            href='http://www.worksodisha.gov.in/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dept11} alt='works-odisha' />{' '}
          </a>
        </div>
      </Carousel>
    </>
  );
};
export default CarouselItems;
