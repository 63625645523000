import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  SecretaryBuilding,
  OpamsLogo,
  ORSACLogo,
  Log5T,
  Log5TYellow,
  TeamWork,
  Technology,
  TimeLimit,
  Transparency,
  Transformation,
  Goplus,
  ODIIS,
  FourkGIO,
  OFMS,
  Checkdam,
  Road,
  Ofaris,
  Iklms,
} from '../../assets/images';

import {Navbar, Footer} from '../../components';
import About from './about';

import './style.css';
import './feature.css';

const Landing = () => {
  return (
    <>
      <Navbar />
      <section class='slider'>
        <div class='container-fluid p-0'>
          <div class='main-slider'>
            <div>
              <div
                class='focal-point h-100'
                data-focus-x='55'
                data-focus-y='50'
                style={{ marginTop: '5rem' }}
              >
                <img src={SecretaryBuilding} alt='secretariat_building' />
              </div>

              <div class='position-absolute pos-left-top w-100'>
                <div class='container justify-content-center d-flex'>
                  <div class='mt-3 slide-cont'>
                    <Row className='justify-content-md-center'>
                      <Col xxl={3} xl={2} md={2} className='first-col-logo'>
                        <img
                          src={OpamsLogo}
                          class='img-fluid opams-logo mx-auto'
                          alt='opams-logo'
                        />
                      </Col>
                      <Col
                        xxl={9}
                        xl={10}
                        md={10}
                        className='header-text-section'
                      >
                        <h1 class='display-3 opams-color'>
                          <strong>O P A M S</strong>
                        </h1>
                        <h2 class='display-5  opams-full-text'>
                          <span class='text-danger'>
                            <span style={{ fontWeight: '400' }}>O</span>
                          </span>
                          disha{' '}
                          <span class='text-danger'>
                            <span style={{ fontWeight: '400' }}>P</span>
                          </span>
                          ublic{' '}
                          <span class='text-danger'>
                            <span style={{ fontWeight: '400' }}>A</span>
                          </span>
                          sset{' '}
                          <span class='text-danger'>
                            <span style={{ fontWeight: '400' }}>M</span>
                          </span>
                          anagement{' '}
                          <span class='text-danger'>
                            <span style={{ fontWeight: '400' }}>S</span>
                          </span>
                          ystem{' '}
                        </h2>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class='position-relative z-100'>
            <div class='position-absolute z-100 orsaclogo'>
              <img src={ORSACLogo} class='img-fluid' alt='opams' />
            </div>
          </div>
        </div>

        {/* <section class='five-t-sec'>
          <div class='sec-first'>
            <div class='five-t-logo'>
              <!--<img src={Log5T} class='five-t-normal' alt='5T' />-->
            </div>

            <div class='content'>
              <ul class='five-t-listing'>
                <li>
                  <span class='txtitm'>
                    <a title='Teamwork' href='/#'>
                      <img
                        src={TeamWork}
                        width='42'
                        height='42'
                        alt='image1'
                        typeof='Image'
                      />
                    </a>{' '}
                  </span>
                  <a href='/vision-5t' title='Teamwork'>
                    Teamwork
                  </a>
                </li>
                <li>
                  <span class='txtitm'>
                    <a href='/#' title='Technology'>
                      <img
                        src={Technology}
                        width='41'
                        height='41'
                        alt='technology'
                        typeof='Image'
                      />
                    </a>{' '}
                  </span>
                  <a href='/vision-5t' title='Technology'>
                    Technology
                  </a>
                </li>
                <li>
                  <span class='txtitm'>
                    <a href='/#' title='Time'>
                      <img
                        src={TimeLimit}
                        width='37'
                        height='37'
                        alt='image1'
                        typeof='Image'
                      />
                    </a>{' '}
                  </span>
                  <a href='/vision-5t' title='Time'>
                    Time
                  </a>
                </li>
                <li>
                  <span class='txtitm'>
                    <a href='/#' title='Transparency'>
                      <img
                        src={Transparency}
                        width='41'
                        height='41'
                        alt='transparency'
                        typeof='Image'
                      />
                    </a>{' '}
                  </span>
                  <a href='/vision-5t' title='Transparency'>
                    Transparency
                  </a>
                </li>
                <li>
                  <span class='txtitm'>
                    <a href='/#' title='Transformation'>
                      <img
                        src={Transformation}
                        width='36'
                        height='36'
                        alt='image1'
                        typeof='Image'
                      />
                    </a>{' '}
                  </span>
                  <a href='/#' title='Transformation'>
                    Transformation
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class='sec-second '>
            <div class='content'>
              <ul style={{ marginLeft: '0' }}>
                <li>
                  <a
                    href='https://gis.investodisha.gov.in/'
                    title='GO PLUS'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={Goplus}
                        width='45'
                        height='45'
                        alt='GO PLUS'
                        typeof='Image'
                      />
                    </div>
                    GO PLUS
                  </a>
                </li>
                <li>
                  <a
                    href='https://odishairrigation.gov.in/'
                    title='ODIIS'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={ODIIS}
                        width='45'
                        height='45'
                        alt='ODIIS'
                        typeof='Image'
                      />
                    </div>
                    ODIIS
                  </a>
                </li>
                <li>
                  <a
                    href='https://odisha4kgeo.in/'
                    title='Odisha 4k GEO'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={FourkGIO}
                        width='45'
                        height='45'
                        alt='Odisha 4k GEO'
                        typeof='Image'
                      />
                    </div>
                    Odisha 4k GEO
                  </a>
                </li>
                <li>
                  <a
                    href='https://odishaforestgis.in/ofms/'
                    title='OFMS'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={OFMS}
                        width='45'
                        height='45'
                        alt='OFMS'
                        typeof='Image'
                      />
                    </div>
                    OFMS
                  </a>
                </li>
                <li>
                  <a
                    href='https://checkdam.odisha4kgeo.in/'
                    title='CHECKDAM'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={Checkdam}
                        width='45'
                        height='45'
                        alt='CHECKDAM'
                        typeof='Image'
                      />
                    </div>
                    CHECKDAM
                  </a>
                </li>
                <li>
                  <a
                    href='https://roadnetworksodisha.in/'
                    title='ROAD'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={Road}
                        width='45'
                        height='45'
                        alt='ROAD'
                        typeof='Image'
                      />
                    </div>
                    ROAD
                  </a>
                </li>
                <li>
                  <a
                    href='https://ofaris.in/'
                    title='OFARIS'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={Ofaris}
                        width='45'
                        height='45'
                        alt='OFARIS'
                        typeof='Image'
                      />
                    </div>
                    OFARIS
                  </a>
                </li>
                <li>
                  <a
                    href='http://www.iklmsodisha.in/'
                    title='IKLMS'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div class='icon'>
                      <img
                        src={Iklms}
                        width='45'
                        height='45'
                        alt='IKLMS'
                        typeof='Image'
                      />
                    </div>
                    IKLMS
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
      </section>
      <About />
      <Footer />
    </>
  );
};

export default Landing;
