import axios from 'axios';
import { axiosConfig } from '../../utilities/axios.config';

// Asset Image Listing
export const UpdatePreference = async (data, token) => {
  var config = axiosConfig(token);

  return await axios
    .post('/ddo/updateDDOPreference', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
