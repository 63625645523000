import { SuccessToast } from '../components/CustomToast/CustomToast';

export const onClickLogoutHandler = () => {
  // Clear cache on logout
  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  SuccessToast('Successfully logged out!');
  localStorage.clear();
  clearCacheData();
  return true;
};
