import axios from 'axios';
import { axiosConfig } from '../utilities/axios.config';

// Asset Listing by default value
export const AssetListing = async (data, token) => {
  var config = axiosConfig(token);

  return await axios
    .post(`/assets/getAllAssetList`, data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Filter asset listing
export const FilterAssetListing = async (data, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/assets/getAllAssetList', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// District api call
export const DistrictListing = async (token) => {
  var config = axiosConfig(token);
  return await axios
    .get('/boundary/getAllDistrict', config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Block api call
export const BlockListing = async (distId, token) => {
  var data = new FormData();
  data.append('distId', distId);

  var config = axiosConfig(token);

  return await axios
    .post('/boundary/getBlocksByDistId', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Gram api call
export const GramListing = async (distId, blockId, token) => {
  var data = new FormData();
  data.append('distId', distId);
  data.append('blockId', blockId);

  var config = axiosConfig(token);

  return await axios
    .post('/boundary/getGpByBlockIdAndDistId', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Village api call
export const VillageListing = async (gramId, blockId, distId, token) => {
  var data = new FormData();
  data.append('gpId', gramId);
  data.append('blockId', blockId);
  data.append('distId', distId);

  var config = axiosConfig(token);
  return await axios
    .post('/boundary/getVillageByGpBlockAndDistId', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
// First Row End

// Second Row Start
// Department api call
export const DepartmentListing = async (token) => {
  var config = axiosConfig(token);
  return await axios
    .get('/boundary/getAllDepartments', config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Controlling Officer api call
export const ControllingOfficerListing = async (data, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/co/getCoList', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Class api call without dept iD
// export const ClassListing = async (token) => {
//   var config = axiosConfig(token);
//   return await axios
//     .post('/boundary/getClassByDeptId', config)
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       console.log(error);
//       return false;
//     });
// };

export const AssetClassListing = async (token) => {
  var config = axiosConfig(token);
  // var formData = new FormData();
  // formData.append('dept_id', 1);

  return await axios
    .post(`/boundary/getClassByDeptId`, '', config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Asset Category api call
export const AssetCategoryListing = async (assetClassId, token) => {
  var data = new FormData();
  data.append('class_id', assetClassId);

  var config = axiosConfig(token);
  return await axios
    .post('/boundary/getCategoryByClassId', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Change Approval status
export const ApproveStatusChange = async (data, token) => {
  var config = axiosConfig(token);

  return await axios
    .post('/assets/approveAsset', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
