import React, { useEffect, useState } from 'react';
import { Form, Collapse, Row, Col } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import CustomButton from '../CustomButton';
import { ErrorToast } from '../CustomToast/CustomToast';
import { loginTokenAtom } from '../../recoil/atom/loginTokenAtom';
import {
  BlockListing,
  DistrictListing,
  GramListing,
  VillageListing,
} from '../../services/assetListing';

const SampleFilter = (props) => {
  const {
    register,
    handleSubmit,
    getValues,
    onSubmitFilter,
    onClickResetHandler,
  } = props;


    const styleForSearchButton = {
      backgroundColor: '#0079c1',
      borderColor: '#0079c1',
      marginRight: '10px',
      width: '30%',
    };

    const styleForResetButton = {
      backgroundColor: 'transparent',
      borderColor: '#0079c1',
      color: '#0079c1',
      width: '30%',
    };

    const [token] = useRecoilState(loginTokenAtom);
    const [openAdvanceFilter, setOpenAdvanceFilter] = useState(false);
    const [blockArray, setBlockArray] = useState([]);
    const [villageArray, setVillageArray] = useState([]);
    const [districtArray, setDistrictArray] = useState([]);
    const [gramPanchayatArray, setGramPanchayatArray] = useState([]);

    // On click district event handler
    const onChangeDistrict = (value) => {
      setGramPanchayatArray([]);
      setVillageArray([]);

      var districtValue = parseInt(value);
      BlockListing(districtValue, token)
        .then((response) => {
          if (response && response.status === 1) {
            setBlockArray(response.data.blockList);
          } else {
            setBlockArray([]);
            ErrorToast('No block found!');
          }
        })
        .catch((error) => {
          console.error(error);
          setBlockArray([]);
        });
    };

    // On click district event handler
    const onChangeBlock = (value) => {
      let districtId = getValues('district_id');
      let blockId = parseInt(value);
      setVillageArray([]);

      // Call Block api and set response data into block state variable
      GramListing(districtId, blockId, token)
        .then((response) => {
          if (response && response.status === 1) {
            setGramPanchayatArray(response.data.gpList);
          } else {
            setGramPanchayatArray([]);
            ErrorToast('No GP found!');
          }
        })
        .catch((error) => {
          console.error(error);
          setGramPanchayatArray([]);
        });
    };

    // On click district event handler
    const onClickGramPanchayat = (value) => {
      let districtId = getValues('district_id');
      let blockId = getValues('block_id');
      let gpId = value;

      VillageListing(gpId, blockId, districtId, token)
        .then((response) => {
          if (response && response.status == 1) {
            setVillageArray(response.data.villageList);
          } else {
            setVillageArray([]);
            ErrorToast('No village found!');
          }
        })
        .catch((error) => {
          console.error(error);
          setVillageArray([]);
        });
    };



    useEffect(() => {
      const districtListing = () => {
        // District Listing
        DistrictListing(token)
          .then((response) => {
            if (response && response.status === 1)
              setDistrictArray(response.data.districtList);
            else setDistrictArray([]);
          })
          .catch((error) => {
            console.log(error);
            setDistrictArray();
          });
      };
      districtListing();
    }, [token]);

  return (
    <Form className='text-left' onSubmit={handleSubmit(onSubmitFilter)}>
      <Form.Group className='mb-3 d-flex flex-row'>
        <Form.Label className='w-25'>District</Form.Label>
        <Form.Select
          size='sm'
          name='district_id'
          {...register('district_id', {
            valueAsNumber: true,
            onChange: (e) => onChangeDistrict(e.target.value),
          })}
        >
          <option value={0}>Select</option>
          {districtArray.length > 0 &&
            districtArray.map((district) => {
              return (
                <option value={district.dist_id} key={district.dist_id}>
                  {district.district_name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>

      <Form.Group className='mb-3 d-flex flex-row'>
        <Form.Label className='w-25'>Block</Form.Label>
        <Form.Select
          size='sm'
          name='block_id'
          {...register('block_id', {
            valueAsNumber: true,
            onChange: (e) => onChangeBlock(e.target.value),
          })}
        >
          <option value={0}>Select</option>
          {blockArray.length > 0 &&
            blockArray.map((block) => {
              return (
                <option value={block.block_id} key={block.block_id}>
                  {block.block_name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>

      <Form.Group className='mb-3 d-flex flex-row'>
        <Form.Label className='w-25'>GP</Form.Label>
        <Form.Select
          size='sm'
          {...register('gp_id', {
            valueAsNumber: true,
            onChange: (e) => onClickGramPanchayat(e.target.value),
          })}
        >
          <option value={0}>Select</option>
          {gramPanchayatArray.length > 0 &&
            gramPanchayatArray.map((gram, index) => {
              return (
                <option value={gram.gp_id} key={index}>
                  {gram.grampanchayat_name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>

      <Form.Group className='mb-3 d-flex flex-row'>
        <Form.Label className='w-25'>Village</Form.Label>
        <Form.Select
          size='sm'
          {...register('village_id', {
            valueAsNumber: true,
          })}
        >
          <option value={0}>Select</option>
          {villageArray.length > 0 &&
            villageArray.map((village, index) => {
              return (
                <option value={village.village_id} key={index}>
                  {village.revenue_village_name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>

      <div
        onClick={() => setOpenAdvanceFilter(!openAdvanceFilter)}
        aria-expanded={openAdvanceFilter}
        className='display-6 pt-2 pb-1 mb-3'
        style={{
          fontSize: '16px',
          borderBottom: '1px solid lightgray',
          cursor: 'pointer',
        }}
      >
        Advance Filter
      </div>

      {/* Query based search */}
      {/* <Collapse timeout={1400} in={openAdvanceFilter}>
        <Row>
          <Col md={4} xs={12} className='me-0 pe-0'>
            <Form.Group className='mb-3 d-flex flex-row'>
              <Form.Select
                size='sm'
                {...register('type_id', {
                  valueAsNumber: true,
                })}
              >
                <option value={0}>Set</option>
                <option value={1}>Amount</option>
                <option value={2}>Type</option>
                <option value={3}>Area</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} xs={12} className='ps-1 me-0 pe-0'>
            <Form.Group className='mb-3 d-flex flex-row'>
              <Form.Select size='sm' {...register('symbol_id', {})}>
                <option value={0}>Set</option>
                <option value={'<'}> {'<'} </option>
                <option value={'>'}> {'>'} </option>
                <option value={'<='}> {'<='} </option>
                <option value={'>='}> {'>='} </option>
                <option value={'='}> {'='}</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} xs={12} className='ms-0 ps-1'>
            <Form.Group className='mb-3 d-flex flex-row'>
              <Form.Control
                size='sm'
                type='text'
                {...register('value_id', {})}
              />
            </Form.Group>
          </Col>
        </Row>
      </Collapse> */}
      {/* Advance filter form */}

      <div className='text-right mt-4'>
        <CustomButton
          style={styleForSearchButton}
          handleClick={() => handleSubmit(onSubmitFilter)}
        >
          Filter
        </CustomButton>
        <CustomButton
          style={styleForResetButton}
          handleClick={(e) => onClickResetHandler(e)}
        >
          Reset
        </CustomButton>
      </div>
    </Form>
  );
};
export default SampleFilter;
