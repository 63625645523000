import React from 'react';
import { Collapse } from 'react-bootstrap';
import CollapseCard from '../CollapseCardForMap';

const CustomCollapse = (props) => {
  const {
    open,
    handleCloseCollapse,
    cardHeadingStyle,
    collapseContainerStyle,
    name,
  } = props;

  const propsForCard = {
    handleCloseCollapse,
    cardHeadingStyle,
    name,
  };

  return (
    <Collapse in={open} timeout={200}>
      <div id='example-collapse-text' style={collapseContainerStyle}>
        <CollapseCard {...propsForCard} />
      </div>
    </Collapse>
  );
};

export default CustomCollapse;
