import React, { useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import { Row, Col } from 'react-bootstrap';
import { terrainImageUrl, roadImageUrl } from '../../../backend';
import { RoadMap, BingMap, NoBaseMap } from '../../../assets/images';
import BusinessLogic from '../Sidebar/BusinessLogic';

const MapList = (props) => {
  const { onClickImage, layerName } = props;

  const jsonForMapLayer = [
    {
      id: 0,
      boundary_name: 'OSM',
      img_url: RoadMap,
    },
    {
      id: 1,
      boundary_name: 'Terrain',
      img_url: terrainImageUrl,
    },
    {
      id: 2,
      boundary_name: 'Road',
      img_url: roadImageUrl,
    },
    {
      id: 3,
      boundary_name: 'Bing',
      img_url: BingMap,
    },
    {
      id: -1,
      boundary_name: 'Basemap',
      img_url: NoBaseMap,
    },
  ];

  return (
    <section>
      <Row className='ps-2 pe-2'>
        {jsonForMapLayer.length > 0 &&
          jsonForMapLayer.map((layer, index) => {
            return (
              <>
                <Col
                  className='me-0 pe-3 ps-3 ms-2  pt-2'
                  style={{
                    cursor: 'pointer',
                    // border:
                    //   layerName === layer.boundary_name && '1px solid #dedede',
                    marginRight: index == 4 && '2rem',
                  }}
                >
                  <div
                    onClick={() =>
                      onClickImage(layer.id)(layer.img_url)(layer.boundary_name)
                    }
                  >
                    <img src={layer.img_url} alt='Satellite' height={28} />
                  </div>
                  <small className='map-type-text'>{layer.boundary_name}</small>
                </Col>
              </>
            );
          })}
      </Row>
    </section>
  );
};

const MapType = (props) => {
  const { name, onClickImage, image, layerName } = props;

  const { collapsed } = BusinessLogic();

  const styleForLayerContainer = {
    boxShadow: 'rgb(0 0 0 / 47%) 0px 22px 70px 4px',
    backgroundColor: '#F5F5F7',
    border: '1px solid lightgray',
  };

  const imageProps = { onClickImage, layerName };

  useEffect(() => {
    collapsed && name.render();
  }, [collapsed]);

  return (
    <div className='map-type-section p-1 m-0' style={styleForLayerContainer}>
      <Tooltip
        theme='light'
        position='right'
        html={<MapList {...imageProps} />}
        hideOnClick={true}
        animation='shift'
        distance={20}
        interactive={true}
      >
        <div className='d-flex flex-column' style={{ borderRadius: '50%' }}>
          <img src={image} alt='road-map' height={60} />
        </div>
      </Tooltip>
    </div>
  );
};

export default MapType;
