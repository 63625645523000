import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useRecoilState } from 'recoil';
import { loginTokenAtom } from '../../../recoil/atom/loginTokenAtom';
// import { sampleJSONData } from './SampleJSONData';
import { DepartmentDrilldown } from '../../../services/Dashboard/DepartmentDrilldown.helper';
import './chartStyleForBargraph.css';

const Bargraph = (props) => {
  const { seriesData, titleText, yAxisText, colorCode } = props;

  const userData = JSON.parse(localStorage.getItem('user'));
  const [token] = useRecoilState(loginTokenAtom);

  var easeOutBounce = function (pos) {
    if (pos < 1 / 2.75) {
      return 7.5625 * pos * pos;
    }
    if (pos < 2 / 2.75) {
      return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
    }
    if (pos < 2.5 / 2.75) {
      return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
    }
    return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
  };

  Math.easeOutBounce = easeOutBounce;

  // set api data as drilldown data
  const data = {
    chart: {
      type: 'column',
      events: {
        drilldown: function (e) {
          var drilldownResponseData;
          var arrayJson = [];
          var name = e.point.options.name;
          var trimmedName = name.split(' ')[0];
          console.log(trimmedName);
          var departmentId = e.point.options.dept_id;

          // create an object for new
          const objectForDrilldown = {};
          let jsonData = {
            userId: userData.user_id,
            deptId: departmentId,
          };

          DepartmentDrilldown(jsonData, token).then((response) => {
            if (parseInt(response.data.status) == 1) {
              drilldownResponseData = response.data.data;
              // arrayJson.push(drilldownResponseData);
              console.log(arrayJson);

              // loop the json Object
              for (var key in drilldownResponseData) {
                if (drilldownResponseData.hasOwnProperty(key)) {
                  var val = drilldownResponseData[key];

                  if (key === trimmedName) {
                    let dataOfArray = val.data;
                    dataOfArray.forEach(function (item) {
                      delete item.classId;
                      objectForDrilldown[trimmedName] = val;
                    });
                  }

                  if (key !== trimmedName) {
                    let dataOfArray = val.data;
                    // create temp object
                    const objectTemp = {
                      id: val.id,
                      // data: [],
                    };
                    // objectForDrilldown[key] = objectTemp;

                    // dataOfArray.forEach((item, index) => {
                    //   let ar = [];
                    //   if (index === 0) {
                    //     ar.push(item[index]);
                    //   } else {
                    //     ar.push(parseInt(item[index]));
                    //   }
                    //   objectTemp[data] = ar;
                    // });
                  }
                }
              }

              arrayJson.push(objectForDrilldown);

              if (!e.seriesOptions) {
                var chart = this;
                var drilldown2 = arrayJson;
                var series3 = drilldown2[0][trimmedName];
  
                chart.showLoading('Getting data...');
                setTimeout(function () {
                  chart.hideLoading();
                  chart.addSeriesAsDrilldown(e.point, series3);
                }, 500);
              }
            }
          });

          // var seriesDemo = {};

          // if (sampleJSONData.length > 0) {
          //   let demoJson = {};
          //   demoJson.name = name;
          //   demoJson.id = name;
          //   demoJson.data = sampleJSONData[0]['Excise (EXE)'].data;
          //   seriesDemo[name] = demoJson;
          // }
          // var data1 = {};
          // var data2 = {};
          // var data3 = {};
          // var dataCount = [];
          // var series1 = {};
          // var responseData = [];

          // data1.name = name;
          // data1.id = name;
          // data1.data = [
          //   {
          //     name: 'Land(01)',
          //     y: 22,
          //     drilldown: 'Land()',
          //   },
          //   {
          //     name: 'Machinery(04)',
          //     y: 23,
          //     drilldown: 'Machinery(04)',
          //   },
          // ];
          // series1[name] = data1;

          // if (data1.data.length > 0) {
          //   data1?.data.forEach(function (item, index) {
          //     data2.id = data1.data[0].name;
          //   });
          // }

          // data2.id = data1.data[0].name;
          // data2.data = [['Land', 17]];
          // series1[data1.data[0].name] = data2;

          // data3.id = data1.data[1].name;
          // data3.data = [
          //   ['Solar Setup', 12],
          //   ['Generator', 23],
          //   ['Fogger Machine', 24],
          //   ['Drill Machine', 25],
          // ];
          // series1[data1.data[1].name] = data3;

          // var resArr = new Array();
          // resArr.push(series1);

          // if (!e.seriesOptions) {
          //   var chart = this;
          //   var drilldown = resArr;
          //   var series = drilldown[0][e.point.name];

          //   chart.showLoading('Getting data...');
          //   setTimeout(function () {
          //     chart.hideLoading();
          //     chart.addSeriesAsDrilldown(e.point, series);
          //   }, 500);
          // }
        },
      },
    },
    title: {
      text: titleText,
    },
    xAxis: {
      type: 'category',
      labels: {
        align: 'center',
        style: {
          color: '#029296',
          fontSize: '10.7px',
          fontFamily: 'Raleway',
          fontWeight: '100',
        },
      },
    },
    yAxis: {
      title: {
        text: yAxisText,
        style: {
          color: '#346284',
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        colorByPoint: true,
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: yAxisText,
        colorByPoint: true,
        data: seriesData,
        dataLabels: {
          enabled: true,
          rotation: -90,
          align: 'right',
          x: 4,
          y: 10,
          style: {
            fontSize: '11px',
            fontFamily: 'Raleway',
            textDecoration: 'none',
          },
        },
        animation: {
          duration: 1500,
          easing: easeOutBounce,
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
          },
        },
      ],
    },
    colors: colorCode,
    credits: {
      enabled: false,
    },
    drilldown: {
      series: [],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={data} />
    </div>
  );
};

export default Bargraph;
