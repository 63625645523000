import React, { useRef } from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { useRecoilState } from 'recoil';
import { HiOutlineMinusSm } from 'react-icons/hi';
import { BsList } from 'react-icons/bs';
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { menuListAtom } from '../../../recoil/atom/menuList.atom';
import OPAMSLogo from '../../../assets/images/Opams.png';
import './App.scss';
import './Sidebar.css';

const Aside = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
  onClick,
}) => {
  const leftCircleRef = useRef();
  const rightCircleRef = useRef();
  const textLogoRef = useRef();
  const sidebarHeaderLogoSecRef = useRef();
  const history = useHistory();
  const [menuList] = useRecoilState(menuListAtom);

  // On click menu item handler
  const onClickMenuItem = (e, targetUrl) => {
    e.preventDefault();
    history.push(`/${targetUrl}`);
  };

  let sidebarContentListing;
  if (menuList.length > 0) {
    sidebarContentListing =
      menuList.length > 0 ? (
        menuList.map((menu) => (
          <Menu iconShape='circle'>
            {menu.children.length > 0 ? (
              <SubMenu title={menu.label} icon={<BsList size={19} />}>
                {menu.children.map((child, index) => (
                  <MenuItem
                    key={index}
                    icon={<HiOutlineMinusSm />}
                    onClick={(e) => onClickMenuItem(e, child.target_url)}
                  >
                    {child.label}
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <>
                <MenuItem
                  icon={<HiOutlineMinusSm size={19} />}
                  onClick={(e) => onClickMenuItem(e, menu.target_url)}
                >
                  <a href='dashboard'>
                    <span className='mtext'>{menu.label}</span>
                  </a>
                </MenuItem>
              </>
            )}
          </Menu>
        ))
      ) : (
        <></>
      );
  }

  // On click collapse button handler
  const onClickCollapseHandlers = () => {
    if (collapsed) {
      leftCircleRef.current.style.display = 'block';
      rightCircleRef.current.style.display = 'none';
      textLogoRef.current.style.display = 'block';
    } else {
      leftCircleRef.current.style.display = 'none';
      sidebarHeaderLogoSecRef.current.style.paddingRight = '0px';
      rightCircleRef.current.style.display = 'block';
      textLogoRef.current.style.display = 'none';
    }
    handleCollapsedChange();
  };


  return (
    <ProSidebar
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint='md'
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className='d-flex p-2'>
          <div
            className='ms-2'
            ref={sidebarHeaderLogoSecRef}
            style={{ paddingRight: '20px' }}
          >
            <img src={OPAMSLogo} alt='opams' style={{ width: '51px' }} />
          </div>
          <div
            ref={textLogoRef}
            style={{
              padding: '12px 24px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 19,
              letterSpacing: '4px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: 'rgb(69 175 165)',
              textAlign: 'center',
            }}
          >
            OPAMS
          </div>
        </div>
      </SidebarHeader>
      <SidebarContent>{sidebarContentListing}</SidebarContent>

      <SidebarFooter
        style={{ textAlign: 'center' }}
        onClick={() => onClickCollapseHandlers()}
      >
        <div ref={leftCircleRef}>
          <AiOutlineLeftCircle size={23} className='m-2' />
        </div>
        <div ref={rightCircleRef} style={{ display: 'none' }}>
          <AiOutlineRightCircle size={23} className='m-2' />
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
