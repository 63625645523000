import React from 'react';
import { Button } from 'react-bootstrap';

const index = ({ children, handleClick, style }) => {
  return (
    <Button onClick={handleClick} style={style} type='submit'>
      {children}
    </Button>
  );
};

export default index;
