import React, { useEffect, useState } from 'react';
import Highcharts, { color } from 'highcharts';
import { useRecoilState } from 'recoil';
import drilldown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';
import { collapseSidebarAtom } from '../../../recoil/atom/collapseSidebarAtom';
import './chartStyleForPie.css';

drilldown(Highcharts);

const Piechart = (props) => {
  const {
    seriesData,
    titleText,
    subtitleText,
    containBoxText,
    widthOfLegends,
    colorCode,
  } = props;
  const [collapsed] = useRecoilState(collapseSidebarAtom);

  const data = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: '540rem',
      reflow: true,
    },
    title: {
      text: titleText,
      align: 'center',
      style: {
        color: '#0F3573',
        margin: 10,
        fontWeight: 'bold',
        fontSize: '15px',
        fontFamily: 'Raleway',
        letterSpacing: 2,
      },
    },
    subtitle: {
      text: subtitleText,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: '%',
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            // legend: {
            //   align: 'left',
            //   verticalAlign: 'bottom',
            //   layout: 'horizontal',
            //   x: 0,
            //   z: 0,
            //   y: 10,
            //   itemStyle: {
            //     color: '#000000',
            //     fontWeight: '500',
            //     fontSize: '10px',
            //     fontFamily: 'Montserrat',
            //   },
            // },
            legends: {
              layout: 'horizontal',
              backgroundColor: '#FFFFFF',
              align: 'center',
              verticalAlign: 'top',
              floating: true,
              // x: 90,
              // y: 45,
              width: '90px',
            },

            yAxis: {
              labels: {
                align: 'left',
                x: -20,
                y: -5,
              },
              title: {
                text: null,
              },
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
            plotOptions: {
              pie: { size: 200 },
            },
          },
        },
      ],
    },
    plotOptions: {
      pie: {
        size: 200,
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y:.0f}',
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      labelFormat: '{name}: {y: .0f}',
      backgroundColor: '#F9F9F9',
      borderColor: 'gray',
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      borderRadius: 5,
      itemMarginBottom: 6,

      // width: `${widthOfLegends}px`,

      // x: -30,
      // z: 60,
      // y: 50,
      shadow: true,
      itemStyle: {
        color: '#000000',
        fontWeight: '500',
        fontSize: '11px',
        fontFamily: 'Montserrat',
      },
      itemHoverStyle: {
        color: '#E0E309',
      },
      symbolHeight: 7,
      symbolWidth: 7,
      symbolRadius: 6,
    },
    tooltip: {
      borderRadius: 12,
      borderColor: '#0F3573',
      shared: true,
      headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> of total<br/>',
    },
    series: [
      {
        name: containBoxText,
        data: seriesData,
      },
    ],
    // colors: ['#8ecae6', '#219ebc', '#023047', '#ffb703', '#fb8500'],
    // colors: [
    //   '#005f73',
    //   '#0a9396',
    //   '#94d2bd',
    //   '#e9d8a6',
    //   '#EE9B00',
    //   '#ee9b00',
    //   '#ca6702',
    //   '#bb3e03',
    //   '#ae2012',
    //   '#9b2226',
    // ],
    // colors: [
    //   '#f72585',
    //   '#b5179e',
    //   '#7209b7',
    //   '#560bad',
    //   '#480ca8',
    //   '#3a0ca3',
    //   '#3f37c9',
    //   '#4361ee',
    //   '#4895ef',
    //   '#4cc9f0',
    // ],
    colors: colorCode,
    // colors: ['rgb(130, 0, 0)'],
    // colors: [
    //   '#f6bd60',
    //   '#f7c981',
    //   '#f7d5a1',
    //   '#f7ede2',
    //   '#f6dcd3',
    //   '#f5cac3',
    //   '#bdb8b0',
    //   '#84a59d',
    //   '#bb9590',
    //   '#f28482',
    // ],
  };

  useEffect(() => {
    if (collapsed) {
      for (const chart of Highcharts.charts) {
        if (chart) {
          chart.reflow();
          chart.redraw();
          // chart.setSize(700, 540);
        }
      }
    }
  }, [collapsed]);

  return (
    <div>
      <HighchartsReact
        allowChartUpdate={true}
        highcharts={Highcharts}
        options={data}
      />
    </div>
  );
};

export default Piechart;
