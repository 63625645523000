import React, { useRef, useContext, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button, Form, Row, Col } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { HiOutlineHome } from 'react-icons/hi';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import {
  LoginHelper,
  ForgotPassHelper,
  VerifyPassHelper,
} from '../../services/loginHelper';
import { TokenContext } from '../../context/auth.context';
import { MasterSync } from '../../services/masterSyncHelper';
import { emailIdValidation } from '../../utilities/emailid.validation';

import { OpamsLogo } from '../../assets/images';
// Atoms
import { loginTokenAtom } from '../../recoil/atom/loginTokenAtom';
import { disposalTypeAtom } from '../../recoil/atom/disposeTypeAtom';
import { attributeAtom } from '../../recoil/atom/attributeAtom';
import { disposalMethodAtom } from '../../recoil/atom/disposeMethodAtom';
import { classFilterAtom } from '../../recoil/atom/classFilterAtom';
import { approvalStatusAtom } from '../../recoil/atom/approvalStatusAtom';
import { acquisitionNatureAtom } from '../../recoil/atom/acquisitionNatureAtom';
import { categoryFilterAtom } from '../../recoil/atom/categoryFilterAtom';
import { assetConditionAtom } from '../../recoil/atom/assetConditionAtom';
import { assetTypeAtom } from '../../recoil/atom/assetTypeAtom';
import { designationAtom } from '../../recoil/atom/designationAtom';
import { departmentAtom } from '../../recoil/atom/departmentAtom';
import { menuListAtom } from '../../recoil/atom/menuList.atom';
import {
  ErrorToast,
  ErrorToastWithToastId,
  SuccessToast,
  SuccessToastWithToastId,
} from '../../components/CustomToast/CustomToast';
import { hierarchyAtom } from '../../recoil/atom/hierarchyAtom';
import SendOtpForm from './SendOtpForm';
import { Captcha } from '../../components';
import './login.css';
import { yearListAtom } from '../../recoil/atom/Dashboard/yearList.atom';

const Login = () => {
  const history = useHistory();
  const { setToken } = useContext(TokenContext);

  const userNameRef = useRef();
  const userEmailRef = useRef();
  const forgotRef = useRef();
  const otpRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const userLoginRef = useRef();
  const otpSectionRef = useRef();
  const forgotPasswordRef = useRef();
  const newPasswordFormRef = useRef();
  const signInTextRef = useRef();

  const [loginToken, setLoginToken] = useState('');
  const [loginOTP, setLoginOTP] = useState('');

  const [, setLoginAtomToken] = useRecoilState(loginTokenAtom);
  const [, setDisposeAtomMethod] = useRecoilState(disposalMethodAtom);
  const [, setDisposeAtomType] = useRecoilState(disposalTypeAtom);
  const [, setAttributeAtomData] = useRecoilState(attributeAtom);
  const [, setAcquisitionNature] = useRecoilState(acquisitionNatureAtom);
  const [, setApprovalStatus] = useRecoilState(approvalStatusAtom);

  const [, setAssetAtomCategory] = useRecoilState(categoryFilterAtom);
  const [, setAssetClass] = useRecoilState(classFilterAtom);
  const [, setAssetCondition] = useRecoilState(assetConditionAtom);

  const [, setAssetAtomType] = useRecoilState(assetTypeAtom);
  const [, setAtomDepartment] = useRecoilState(departmentAtom);
  const [, setAtomDesignation] = useRecoilState(designationAtom);
  const [, setHierarchy] = useRecoilState(hierarchyAtom);
  const [, setMenuList] = useRecoilState(menuListAtom);

  const [, setYearList] = useRecoilState(yearListAtom);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // captcha state
  const [generatedCaptcha, setGeneratedCaptcha] = useState('');

  /* Login user form start */
  const defaultValueForLoginForm = {
    username: '',
    password: '',
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues: defaultValueForLoginForm });
  /* Login user form end */

  // On click forgot button handler
  const onClickForgotButtonHandler = (event) => {
    event.preventDefault();
    userLoginRef.current.style.display = 'none';
    forgotRef.current.style.display = 'none';
    forgotPasswordRef.current.style.display = 'block';
    signInTextRef.current.style.display = 'none';
  };

  // On click send otp button handler
  const onClickSendOTPButtonHandler = (event) => {
    event.preventDefault();

    if (userNameRef.current.value == '' || userEmailRef.current.value == '') {
      ErrorToast('All fields are required');
    } else {
      // Check valid email
      const emailValidationResult = emailIdValidation(
        userEmailRef.current.value
      );
      if (emailValidationResult) {
        const toastId = toast.loading('Sending...');
        var form = new FormData();

        form.append('username', userNameRef.current.value);
        form.append('email', userEmailRef.current.value);

        ForgotPassHelper(form).then((response) => {
          if (response && response.status == 1) {
            SuccessToastWithToastId('OTP sent successfully!!', toastId);
            localStorage.setItem('otp', response.data.otp);
            localStorage.setItem('loginToken', response.data.token);
            setLoginToken(response.data.token);
            setLoginOTP(response.data.otp);
            forgotPasswordRef.current.style.display = 'none';
            otpSectionRef.current.style.display = 'block';
          } else if (response && response.status == 0) {
            ErrorToastWithToastId(response.message, toastId);
          }
        });
      } else {
        ErrorToast('Enter valid email address!');
      }
    }
  };
  // On click verify button handler
  const onClickVerifyButtonHandler = (event) => {
    event.preventDefault();

    if (otpRef.current.value == '') {
      ErrorToast('All fields are required!');
    } else {
      if (parseInt(otpRef.current.value) !== parseInt(loginOTP)) {
        ErrorToast('Invalid OTP!');
        otpSectionRef.current.focus();
      } else {
        otpSectionRef.current.style.display = 'none';
        newPasswordFormRef.current.style.display = 'block';
        SuccessToast('OTP Verified successfully!');
      }
    }
  };

  // On click Cancel button handler
  const onClickCancelButtonHandler = (event) => {
    event.preventDefault();
    userLoginRef.current.style.display = 'block';
    forgotRef.current.style.display = 'block';
    signInTextRef.current.style.display = 'block';

    if (otpSectionRef.current.style.display == 'block')
      otpSectionRef.current.style.display = 'none';

    if (newPasswordFormRef.current.style.display == 'block')
      newPasswordFormRef.current.style.display = 'none';

    if (forgotPasswordRef.current.style.display == 'block')
      forgotPasswordRef.current.style.display = 'none';
  };

  // On click password button handler
  const onClickSubmitButton = (event) => {
    event.preventDefault();
    if (
      newPasswordRef.current.value == '' ||
      confirmPasswordRef.current.value == ''
    ) {
      ErrorToast('All fields are required');
    } else {
      if (newPasswordRef.current.value === confirmPasswordRef.current.value) {
        var form = new FormData();
        form.append('token', loginToken);
        form.append('password', newPasswordRef.current.value);

        // Call api
        VerifyPassHelper(form).then((response) => {
          if (response && response.status == 1) {
            SuccessToast('Password changed successfully!!');
            newPasswordFormRef.current.style.display = 'none';
            forgotPasswordRef.current.style.display = 'none';
            userLoginRef.current.style.display = 'block';
            forgotRef.current.style.display = 'block';
            signInTextRef.current.style.display = 'block';
            history.push('/login');
          } else if (response && response.status == 0) {
            ErrorToast(response.message);
          }
        });
      } else {
        ErrorToast('Password does not match!');
      }
    }
  };

  const captchaProps = {
    generatedCaptcha: generatedCaptcha,
    setGeneratedCaptcha: setGeneratedCaptcha,
    register: register,
    errors: errors,
    getValues: getValues,
  };

  // login handler
  const onSubmitLogin = (data) => {
    const { username } = data;

    // start the loading for login
    const toastId = toast.loading('Logging in...');
    let userValue = getValues('captcha');

    if (userValue == generatedCaptcha) {
      delete data['captcha'];

      setTimeout(() => {
        LoginHelper(data)
          .then((response) => {
            if (response && response.status == 1) {
              setToken(response.data.jwtToken);
              setLoginAtomToken(response.data.jwtToken);
              localStorage.setItem('inputUsername', username);
              // save token and user info in local storage
              localStorage.setItem('jwt_token', response.data.jwtToken);
              localStorage.setItem(
                'menu_list',
                JSON.stringify(response.data.menuList)
              );
              setMenuList(response.data.menuList);
              localStorage.setItem('user', JSON.stringify(response.data.user));

              // Master sync api call and store the response into localStorage and global variables
              const jsonDataFormMasterSync = {
                userId: response.data.user.user_id,
                isDefault: 1,
                puaFlag: 0,
              };
              var tokenData = response.data.jwtToken;
              var jsonData = JSON.stringify(jsonDataFormMasterSync);

              // fetch master data
              MasterSync(tokenData, jsonData).then((res) => {
                if (res && res.status === 1) {
                  var masterData = res.data;

                  // loop the json Object and store all the values in localStorage
                  for (var key in masterData) {
                    if (masterData.hasOwnProperty(key)) {
                      var val = masterData[key];
                      key != 'co_master' &&
                        localStorage.setItem(key, JSON.stringify(val));
                    }
                  }

                  setAssetClass(masterData.asset_class);
                  setAssetAtomType(masterData.asset_type);
                  setAtomDepartment(masterData.department);
                  setHierarchy(masterData.hierarchy_master);
                  setAssetAtomCategory(masterData.asset_cat);
                  setAtomDesignation(masterData.designation);
                  setDisposeAtomType(masterData.dispose_type);
                  setAttributeAtomData(masterData.attr_master);
                  setApprovalStatus(masterData.approval_status);
                  setAssetCondition(masterData.asset_condition);
                  setDisposeAtomMethod(masterData.disposal_method);
                  setAcquisitionNature(masterData.acquisition_nature);
                  setYearList(masterData.financial_year);
                  reset();

                  history.push({
                    pathname: '/dashboard',
                    state: {
                      token: response.data.jwtToken,
                    },
                  });
                  SuccessToastWithToastId('Successfully logged in!', toastId);
                } else {
                  ErrorToastWithToastId('Login failed!', toastId);
                }
              });
            } else {
              ErrorToastWithToastId('Login failed!', toastId);
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
            ErrorToastWithToastId('Login failed!', toastId);
            reset();
          });
      }, 1000);
    } else {
      ErrorToastWithToastId('Captcha verification failed!', toastId);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | OPAMS</title>
        <meta name='description' content='Login screen' />
      </Helmet>
      <div className='outer-bg'>
        <Toaster position='top-center' reverseOrder={false} />
        <div className='container-fluid login-page'>
          <div className='position-relative h-100'>
            <div className='login-page-bg position-absolute  d-none d-sm-block'>
              <div className='row h-100 align-items-center'>
                <div className='col-auto d-none d-sm-block h-100 outer-bg'>
                  <div className='blank-width'></div>
                </div>
                <div className='col bg-success bg-gradient bg-opacity-50 h-100'></div>
              </div>
            </div>

            <div className='row justify-content-center align-items-center h-100 login-meta-box g-0'>
              <div className='col-auto'>
                <div className='main-login shadow bg-white'>
                  <div className='row h-100 align-items-center g-0'>
                    <div className='col-6  d-none d-sm-block h-100'>
                      <div className='h-100 login-bg bg-light'>
                        <div className='p-5'>
                          <img
                            className='img-fluid login-logo mx-auto d-block mb-3'
                            src={OpamsLogo}
                            alt='opams'
                          />

                          <h3
                            className='text-center'
                            style={{ fontWeight: '400' }}
                          >
                            <span className='text-danger'>O</span>disha{' '}
                            <span className='text-danger'>P</span>ublic{' '}
                            <span className='text-danger'>A</span>sset{' '}
                            <span className='text-danger'>M</span>anagement{' '}
                            <span className='text-danger'>S</span>ystem{' '}
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className='col-6 col-sm-6 col-md-5 login-section'>
                      <div className='login-panel p-3 p-md-5'>
                        <div className='d-sm-none'>
                          <Row className='align-items-center'>
                            <Col xs={4}>
                              <img
                                className='img-fluid login-logo mb-2'
                                src={OpamsLogo}
                                alt='opams'
                              />
                            </Col>
                            <Col xs={8} className='mt-3'>
                              <h2
                                className='text-center opams-text'
                                style={{ fontWeight: '400' }}
                              >
                                <span className='text-danger'>O</span>disha{' '}
                                <span className='text-danger'>P</span>ublic{' '}
                                <span className='text-danger'>A</span>sset{' '}
                                <span className='text-danger'>M</span>anagement{' '}
                                <span className='text-danger'>S</span>ystem{' '}
                              </h2>
                            </Col>
                          </Row>

                          <hr />
                        </div>

                        <HiOutlineHome
                          style={{
                            position: 'inherit',
                            top: '145px',
                            fontSize: '23px',
                            color: '#176a8a',
                            margin: '15px 0px 15px 0px',
                            zIndex: '9999',
                            cursor: 'pointer',
                          }}
                          onClick={() => history.push('/')}
                        />

                        <h4 className='welcome-header-login'>
                          Welcome to <span className='text-danger'>OPAMS</span>
                        </h4>
                        <p>
                          <small ref={signInTextRef}>
                            Sign in to your account below
                          </small>
                        </p>

                        {/* user login section start */}
                        <Form
                          id='userLogin'
                          name='userLogin'
                          ref={userLoginRef}
                          onSubmit={handleSubmit(onSubmitLogin)}
                        >
                          <input type='hidden' id='userData' name='userData' />
                          <div className='mb-4'>
                            <Form.Group>
                              <Form.Control
                                type='text'
                                placeholder='User ID'
                                name='username'
                                {...register('username', {
                                  required: 'User ID Required!',
                                })}
                              />
                              {errors.username && (
                                <small className='text-danger'>
                                  {errors.username.message}
                                </small>
                              )}
                            </Form.Group>
                          </div>
                          <div className='mb-4'>
                            <Form.Group
                              className='mb-3 pass-wrapper-login'
                              controlId='formPlaintextPassword'
                            >
                              <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Password'
                                name='password'
                                {...register('password', {
                                  required: 'Password Required!',
                                })}
                              />
                              {errors.password && (
                                <small className='text-danger'>
                                  {errors.password.message}
                                </small>
                              )}

                              {showPassword ? (
                                <BsFillEyeFill
                                  className='eye-react-icons-login'
                                  onClick={togglePasswordVisibility}
                                  size={20}
                                  fill={'#808080'}
                                />
                              ) : (
                                <BsFillEyeSlashFill
                                  className='eye-react-icons-login'
                                  onClick={togglePasswordVisibility}
                                  size={20}
                                  fill={'#808080'}
                                />
                              )}
                            </Form.Group>
                            <div>
                              <Captcha {...captchaProps} />
                            </div>
                          </div>
                          <div className='d-flex align-content-center w-100'>
                            <button
                              className='px-4 btn login-button w-100'
                              onClick={() => handleSubmit(onSubmitLogin)}
                            >
                              Sign In
                            </button>
                          </div>
                        </Form>
                        {/* user login section end */}

                        {/* Forgot password section start */}
                        <SendOtpForm
                          forgotPasswordRef={forgotPasswordRef}
                          userNameRef={userNameRef}
                          userEmailRef={userEmailRef}
                          onClickSendOTPButtonHandler={
                            onClickSendOTPButtonHandler
                          }
                          onClickCancelButtonHandler={
                            onClickCancelButtonHandler
                          }
                        />

                        {/* Forgot password section End */}

                        {/* OTP section start */}
                        <form
                          id='userLogin'
                          name='userLogin'
                          method='post'
                          ref={otpSectionRef}
                          style={{ display: 'none' }}
                        >
                          <input type='hidden' id='userData' name='userData' />
                          <div className='mb-4'>
                            <input
                              type='number'
                              className='form-control'
                              placeholder='OTP'
                              name='otp'
                              id='otp'
                              ref={otpRef}
                              required
                            />
                          </div>

                          <div className='d-flex align-content-center'>
                            <Button
                              className='px-4 btn login-button'
                              onClick={(event) =>
                                onClickVerifyButtonHandler(event)
                              }
                            >
                              Verify
                            </Button>

                            <Button
                              className='px-4 btn cancel-button ms-3'
                              onClick={(event) =>
                                onClickCancelButtonHandler(event)
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </form>
                        {/* OTP section End */}

                        {/* New Password Section start */}
                        <form
                          id='userLogin'
                          name='userLogin'
                          method='post'
                          style={{ display: 'none' }}
                          ref={newPasswordFormRef}
                        >
                          <input type='hidden' id='userData' name='userData' />
                          <div className='mb-4'>
                            <input
                              type='password'
                              className='form-control'
                              placeholder='New Password'
                              name='userid'
                              id='userid'
                              required
                              ref={newPasswordRef}
                              onPaste={(e) => e.preventDefault()}
                            />
                          </div>
                          <div className='mb-4'>
                            <input
                              type='password'
                              className='form-control'
                              name='password'
                              id='password'
                              placeholder='Confirm Password'
                              ref={confirmPasswordRef}
                              onPaste={(e) => e.preventDefault()}
                            />
                          </div>
                          <div className='d-flex align-content-center'>
                            <Button
                              className='px-4 btn login-button'
                              onClick={(event) => onClickSubmitButton(event)}
                            >
                              Submit
                            </Button>
                            <Button
                              className='px-4 btn cancel-button ms-3'
                              onClick={(event) =>
                                onClickCancelButtonHandler(event)
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </form>
                        {/* New Password Section end */}

                        {/* Forgot password section start*/}
                        <div
                          className='pb-3'
                          style={{ display: 'block' }}
                          ref={forgotRef}
                        >
                          <hr />
                          <a
                            href='/#'
                            className='forgot-pass-link'
                            onClick={(event) =>
                              onClickForgotButtonHandler(event)
                            }
                          >
                            <div className='forgot-password'>
                              Forgot Password?
                            </div>
                          </a>
                        </div>
                        {/* Forgot password section end*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
