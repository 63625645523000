import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { yearListAtom } from "../../recoil/atom/Dashboard/yearList.atom";
import "./fy.css";

const FinancialYear = (props) => {
  const {
    backgroundColor,
    color,
    onClickFinancialYearHandler,
    finYrList,
    setFinYrList,
  } = props;

  return (
    <div>
      <Form.Select
        size="md"
        style={{
          backgroundColor: backgroundColor,
          border: "none",
          color: color,
        }}
        onChange={(e) => onClickFinancialYearHandler(e)}
      >
        <option value={0}>Select Year</option>
        {finYrList &&
          finYrList.length > 0 &&
          finYrList.map((year) => {
            return (
              <option key={year.id} value={year.id}>
                {year.fin_year}
              </option>
            );
          })}
      </Form.Select>
    </div>
  );
};

export default FinancialYear;
