import React, { useState } from "react";
import { Tooltip } from "react-tippy";
import { useRecoilState } from "recoil";
import { Row, Col } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import HeaderOptions from "./HeaderOptions";
import { PreferenceForm } from "./PreferenceForm";
import { UserView } from "../Modals/ViewUserModal";
import PreferenceModal from "../Modals/PreferenceModal";
import { UserDataFetch } from "../../../services/userHelper";
import { ChangePasswordForm } from "./ChangePassword/ChangePasswordForm";
import {
  ErrorToast,
  SuccessToast,
} from "../../../components/CustomToast/CustomToast";
import { onClickLogoutHandler } from "../../../helper/logout";
import { loginTokenAtom } from "../../../recoil/atom/loginTokenAtom";
import { ChangePassword } from "../../../services/changePassword.helper";
import { FinancialYear, CustomAlert, CustomModal } from "../../../components";
import { GetPreference } from "../../../services/Preference/GetPreference.helper";
import { SavePreference } from "../../../services/Preference/SavePreference.helper";
import { UpdatePreference } from "../../../services/Preference/updatePreference.helper";

const SidebarHeader = ({
  handleToggleSidebar,
  onClickFinancialYearHandler,
  finYrList,
  setFinYrList,
}) => {
  // default value for preference form
  const defaultValueForPreference = {
    custodianName: "",
    custodianPhoneNumber: "",
    custodianOfficeNumber: "",
    custodianEmail: "",
    pincode: "",
    physicalAddress: "",
  };

  // change password react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();

  // Preferences form react-hook-form
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    watch: watch2,
    getValues: getValues2,
    reset: reset2,
  } = useForm({ defaultValues: defaultValueForPreference });

  const defaultValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const history = useHistory();
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("user"));

  const [token] = useRecoilState(loginTokenAtom);
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [preferenceList, setPreferenceList] = useState([]);
  const [preferenceModal, setPreferenceModal] = useState(false);

  // profile modal state
  const [profileModal, setProfileModal] = useState(false);
  const handleProfileModal = () => setProfileModal(false);
  const [userViewData, setUserViewData] = useState(userData);

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const handleChangePasswordModalOpen = () => setChangePasswordModal(true);
  const handleChangePasswordModalClose = () => {
    setChangePasswordModal(false);
    reset(defaultValue);
  };

  // Preferences Modal State

  const handlePreferenceModalClose = () => {
    setPreferenceModal(false);
  };

  // User name and profile image
  const name = userData.first_name + " " + userData.last_name;
  const userImage = userData.profile_image ?? "";

  const dropdownIconStyle = {
    fontSize: "22px",
  };

  const dropDownItemStyle = {
    fontSize: "17px",
    textAlign: "left",
  };
  // Submit button style
  const submitButtonStyle = {
    backgroundColor: "rgb(6 15 27)",
    width: "19%",
    borderColor: "rgb(6 15 27)",
  };

  // Logout on click handler
  const onConfirm = () => {
    setConfirmLogout(true);
  };

  // profile button handler
  const profileHandler = () => {
    var userID = userData.user_id;

    var data = new FormData();
    data.append("id", parseInt(userID));

    UserDataFetch(data, token).then((response) => {
      if (response && response.status == 1) {
        if (response.data.userList.length > 0) {
          setUserViewData(response.data.userList[0]);
        } else {
          setUserViewData([]);
        }
      } else {
        setUserViewData([]);
      }
    });
    setProfileModal(true);
  };

  // On logout button click handler
  const onClickLogout = () => {
    const logout = onClickLogoutHandler();
    logout && history.push("/login");
  };

  // change password handler
  const onClickChangePasswordHandler = (data) => {
    const { oldPassword, newPassword } = data;
    var formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);
    ChangePassword(formData, token).then((response) => {
      if (response.status == 1) {
        SuccessToast("Password changed successfully!");
        handleChangePasswordModalClose();
        reset(defaultValue);
      } else if (response.status == 0) {
        ErrorToast(response.message);
        handleChangePasswordModalClose();
        reset(defaultValue);
      }
    });
  };

  // set preference handler
  const handlePreferenceHandler = async () => {
    // call the api and get the data
    let data = new FormData();
    // TODO: set user id
    data.append("ddoId", userData.desg_id);

    let preferenceResponseData = await GetPreference(data, token);
    if (preferenceResponseData.status == 1) {
      let preferenceData = preferenceResponseData.data.ddoPreferenceList[0];
      setPreferenceList(preferenceResponseData.data.ddoPreferenceList);

      if (preferenceData.length !== 0) {
        reset2({
          custodianName: preferenceData.custodian_name,
          custodianPhoneNumber: preferenceData.custodian_phone_number,
          custodianOfficeNumber: preferenceData.custodian_office_number,
          custodianEmail: preferenceData.custodian_email,
          physicalAddress: preferenceData.asset_physical_address,
          pincode: preferenceData.asset_pincode,
        });
        setPreferenceModal(true);
      } else {
        setPreferenceModal(true);
      }
    } else {
      setPreferenceModal(true);
    }
  };

  const propsForDropdown = {
    setOpenTooltip,
    dropdownIconStyle,
    dropDownItemStyle,
    profileHandler,
    handleChangePasswordModalOpen,
    handlePreferenceHandler,
    onConfirm,
  };

  const propsForFY = {
    backgroundColor: "rgb(80 137 210 / 75%)",
    color: "white",
    onClickFinancialYearHandler: onClickFinancialYearHandler,
    finYrList,
    setFinYrList,
  };

  return (
    <header
      className="text-end sticky-top bg-white justify-content-center"
      style={{
        width: "100%",
        zIndex: "999",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <div className="d-flex justify-content-between">
        <div
          className="btn-toggle  text-start col-2 ms-3 mt-2"
          onClick={() => handleToggleSidebar(true)}
        >
          <FaBars />
        </div>

        <div className="text-end d-flex justify-content-end w-100 align-items-center me-4">
          {location.pathname === "/dashboard" && (
            <div>
              <FinancialYear {...propsForFY} />
            </div>
          )}
        </div>
        <div className="d-flex  col justify-content-end ">
          <Tooltip
            html={<HeaderOptions {...propsForDropdown} />}
            theme="light"
            open={openTooltip}
            onRequestClose={() => setOpenTooltip(false)}
            position="bottom"
            trigger="click"
            interactive
            hideOnClick={true}
            animation="shift"
          >
            <Row
              className="align-items-center d-flex flex-row"
              style={{
                cursor: "pointer",
                width: "10rem",
                height: "62px",
                textAlign: "end",
                display: "flex",
              }}
              onClick={() => setOpenTooltip(!openTooltip)}
            >
              {userImage == "" || userImage == null ? (
                <Col xs={3} className="default-user"></Col>
              ) : (
                <Col xs={3} className="default-user"></Col>
              )}

              <Col
                className="ms-2 user-name p-0 text-left text-muted"
                style={{ fontSize: "17px" }}
              >
                {name}
              </Col>
            </Row>
          </Tooltip>
        </div>

        {/* Change Password Modal */}
        <CustomModal
          show={changePasswordModal}
          handleClose={handleChangePasswordModalClose}
          size="md"
          modalTitleText="Change Password"
          handleSubmit={handleSubmit(onClickChangePasswordHandler)}
          customButtonStyle={submitButtonStyle}
          customButtonText="Change"
          dialogWidth=""
        >
          <ChangePasswordForm
            register={register}
            errors={errors}
            handleSubmit={handleSubmit}
            getValues={getValues}
            watch={watch}
            onClickChangePasswordHandler={onClickChangePasswordHandler}
          />
        </CustomModal>

        {/* Preference Modal */}
        <PreferenceModal
          show={preferenceModal}
          setShow={setPreferenceModal}
          handleClose={handlePreferenceModalClose}
          preferenceList={preferenceList}
          handlePreferenceHandler={handlePreferenceHandler}
        />

        <UserView
          userViewModalShow={profileModal}
          handleCloseUserViewModal={handleProfileModal}
          userViewData={userViewData}
          modalText="Profile"
        />

        {/* For Logout */}
        <CustomAlert
          showModal={confirmLogout}
          setShowModal={setConfirmLogout}
          handleClick={onClickLogout}
          buttonText="Logout"
          headingText="Logout"
        />
      </div>
    </header>
  );
};
export default SidebarHeader;
