import React from 'react';
import { useRecoilState } from 'recoil';
import { Pagination } from 'react-bootstrap';
import { paginationCurrentPageAtom } from '../../recoil/atom/paginationCurrentPage.atom';
import './paginate.css';

const Paginate = ({ totalPages, onClickPaginationItemHandler }) => {
  let items = [];

  const [currentPage, setCurrentPage] = useRecoilState(
    paginationCurrentPageAtom
  );
  const [maxPages, setMaxPages] = React.useState(3);

  let leftSide = currentPage - 3;
  if (leftSide <= 0) leftSide = 1;
  let rightSide = currentPage + 3;
  if (rightSide > maxPages) rightSide = maxPages;

  for (let number = leftSide; number <= rightSide; number++) {
    items.push(
      <>
        <Pagination.Item
          key={number}
          className={number === currentPage && 'active'}
          onClick={() => {
            setCurrentPage(number);
            onClickPaginationItemHandler(number);
          }}
        >
          {number}
        </Pagination.Item>
      </>
    );
  }
  // First page
  const firstPage = () => {
    onClickPaginationItemHandler(1);
    setCurrentPage(1);
  };

  // Last page
  const lastPage = () => {
    onClickPaginationItemHandler(totalPages);
    setCurrentPage(totalPages);
  };

  //   Next page
  const nextPage = async () => {
    if (currentPage < maxPages) {
      await onClickPaginationItemHandler(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  //   Prev page
  const prevPage = async () => {
    if (currentPage > 1) {
      await onClickPaginationItemHandler(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const paginationRender1 = (
    <Pagination>
      <Pagination.First onClick={firstPage} />
      <Pagination.Prev onClick={prevPage} />
      {items}
      <Pagination.Next onClick={nextPage} />
      <Pagination.Last onClick={lastPage} />
    </Pagination>
  );

  React.useEffect(() => {
    setMaxPages(totalPages);
  }, [totalPages]);

  return paginationRender1;
};

export default Paginate;
