import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { collapseSidebarAtom } from '../../../recoil/atom/collapseSidebarAtom';

const BusinessLogic = () => {
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useRecoilState(collapseSidebarAtom);

  const handleCollapsedChange = (checked) => {
    collapsed && setCollapsed(false);
    !collapsed && setCollapsed(true);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  return {
    collapsed,
    toggled,
    handleCollapsedChange,
    handleToggleSidebar,
  };
};

export default BusinessLogic;
