import React from 'react';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';

const ListOfLayers = (props) => {
    const { jsonForBoundaries, onClickBoundary, selectedLayer } = props;
  return (
    <>
      <div className='p-2'>
        {jsonForBoundaries.length > 0 &&
          jsonForBoundaries.map((boundary) => {
            return (
              <div
                key={boundary.id}
                onClick={(e) => onClickBoundary(e, boundary.id)}
                className='pt-0 pb-0 ps-2 ms-3 mb-3 mt-1 boundary-list'
              >
                <div className='d-flex flex-row text-left  align-items-center'>
                  {selectedLayer.includes(boundary.id) ? (
                    <IoIosEyeOff className='me-3' size={20} />
                  ) : (
                    <IoIosEye className='me-3' size={20} />
                  )}
                  <span>{boundary.boundary_name}</span>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ListOfLayers;
