import React from 'react';
import { Modal, Row, Col, Container, Table } from 'react-bootstrap';
import User from '../../../assets/images/user profile.png';
import './Modal.css';

export const UserView = ({
  userViewModalShow,
  handleCloseUserViewModal,
  userViewData,
  modalText,
}) => {
  const userData = userViewData ?? [];
  const roleData = userData.length !== 0 ? userData.roles[0].roleName : '';

  return (
    <>
      <Modal
        show={userViewModalShow}
        onHide={handleCloseUserViewModal}
        aria-labelledby='example-modal-sizes-title-sm'
        animation='true'
        backdrop='static'
        dialogClassName='modal-90w'
      >
        <Modal.Header closeButton className='modal-header-section'>
          <Modal.Title
            id='example-modal-sizes-title-sm'
            style={{
              fontFamily: 'Poppins',
              fontWeight: '500',
              fontSize: '20px',
            }}
          >
            {modalText}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row className='justify-content-center'>
              <div style={{ height: '8rem', width: '8rem', padding: 0 }}>
                <img
                  style={{
                    borderRadius: '50%',
                    height: '8rem',
                    width: '8rem',
                  }}
                  src={userData.profile_image}
                  alt='Avatar'
                  onError={(event) => {
                    event.target.src = User;
                    event.onerror = null;
                  }}
                />
              </div>
            </Row>
            <Row className='mt-5'>
              <Col xs={6}>
                <Table>
                  <tr>
                    <td className='td-user-view'>First Name</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.first_name}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Gender</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.gender}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Mobile No.</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.mobile_no}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Department</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.dept_name}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Hierarchy Level</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.hierarchy_name}</td>
                  </tr>
                </Table>
              </Col>
              <Col xs={6}>
                <Table>
                  <tr>
                    <td className='td-user-view'>Last Name</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.first_name}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Email</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.email}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Designation</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.desg_name}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>Roles</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{roleData}</td>
                  </tr>
                  <tr>
                    <td className='td-user-view'>User Name</td>
                    <td className='semicolon-view-user'>:</td>
                    <td>{userData.username}</td>
                  </tr>
                </Table>

                {/* <ul className='ul-listing'>
                  <li className='d-flex'>
                    <div className='div-user-data'>Last Name</div>
                    <div className='span-text ms-3'>{userData.last_name}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Email</div>
                    <div className='span-text ms-3'>{userData.email}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Designation</div>
                    <div className='span-text ms-3'>{userData.desg_name}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Roles</div>
                    <div className='span-text ms-3'>
                      <div>{roleData}</div>
                    </div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Username</div>
                    <div className='span-text ms-3'>
                      <div>{userData.username}</div>
                    </div>
                  </li>
                </ul> */}
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <ul className='ul-listing'>
                  <li className='d-flex'>
                    <div className='div-user-data'>First Name</div>
                    <div className='span-text ms-3'>{userData.first_name}</div>
                  </li>

                  <li className='d-flex'>
                    <div className='div-user-data'>Gender</div>
                    <div className='span-text ms-3'>{userData.gender}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Mobile No.</div>
                    <div className='span-text ms-3'>{userData.mobile_no}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Department</div>
                    <div className='span-text ms-3'>{userData.dept_name}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Hierarchy Level</div>
                    <div className='span-text ms-3'>
                      {userData.hierarchy_name}
                    </div>
                  </li>
                </ul>
              </Col>
              <Col>
                <ul className='ul-listing'>
                  <li className='d-flex'>
                    <div className='div-user-data'>Last Name</div>
                    <div className='span-text ms-3'>{userData.last_name}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Email</div>
                    <div className='span-text ms-3'>{userData.email}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Designation</div>
                    <div className='span-text ms-3'>{userData.desg_name}</div>
                  </li>
                  <li className='d-flex'>
                    <div className='div-user-data'>Roles</div>
                    <div className='span-text ms-3'>
                      {userData !== undefined ? (
                        userData.roles.map((role, index) => {
                          return <div>{role.roleName}</div>;
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                </ul>
              </Col>
            </Row> */}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const MemoizedUserView = React.memo(UserView);
