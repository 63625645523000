import React, { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';
import BootstrapTable from 'react-bootstrap-table-next';
import { ActiveIcon, InactiveIcon } from '../../../assets/images';
import { useRecoilState } from 'recoil';
import CustomButton from '../../../components/CustomButton';
import {
  ErrorToast,
  SuccessToast,
} from '../../../components/CustomToast/CustomToast';
import { loginTokenAtom } from '../../../recoil/atom/loginTokenAtom';
import { SavePreference } from '../../../services/Preference/SavePreference.helper';
import { UpdatePreference } from '../../../services/Preference/updatePreference.helper';
import { PreferenceForm } from '../Sidebar/PreferenceForm';

const PreferenceModal = ({
  show,
  handleClose,
  preferenceList,
  handlePreferenceHandler,
}) => {
  const submitButtonRef = useRef();
  const [token] = useRecoilState(loginTokenAtom);
  const userData = JSON.parse(localStorage.getItem('user'));
  const [updatePreferenceId, setUpdatePreferenceId] = useState();
  const [showDefault, setShowDefault] = useState(true);

  const activeButtonStyle = {
    color: 'green',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: '2px 7px',
    borderRadius: '21px',
    fontSize: '12px',
  };

  const inactiveButtonStyle = {
    color: 'red',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: '2px 7px',
    borderRadius: '21px',
    fontSize: '12px',
  };

  const defaultValueForPreference = {
    isDefault: false,
    custodianName: '',
    custodianPhoneNumber: '',
    custodianOfficeNumber: '',
    custodianEmail: '',
    assetPincode: '',
    assetPhysicalAddress: '',
  };
  // Preferences form react-hook-form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    reset,
  } = useForm({ defaultValues: defaultValueForPreference });

  // Submit button style
  const submitButtonStyle = {
    backgroundColor: 'rgb(6 15 27)',
    width: '12%',
    borderColor: 'rgb(6 15 27)',
  };
  // Reset button style
  const resetButtonStyle = {
    backgroundColor: 'transparent',
    color: 'rgb(6 15 27)',
    width: '12%',
    borderColor: 'rgb(6 15 27)',
  };

  const onClickUpdateHandler = (rowData) => {
    submitButtonRef.current.innerText = 'Update';
    setUpdatePreferenceId(rowData.id);
    setShowDefault(false);
    // set the value into the form fields
    reset({
      isDefault: rowData.is_default,
      custodianName: rowData.custodian_name,
      custodianEmail: rowData.custodian_email,
      custodianPhoneNumber: rowData.custodian_phone_number,
      custodianOfficeNumber: rowData.custodian_office_number,
      assetPincode: rowData.asset_pincode,
      assetPhysicalAddress: rowData.asset_physical_address,
    });
  };

  const onClickSubmitButtonHandler = async (data) => {
    var preferenceResponseData;

    let buttonText = submitButtonRef.current.innerText;
    data.isActive = true;
    if (buttonText == 'Save') {
      // save api call
      data.userId = userData.user_id;
      data.isActive = true;
      data.createdBy = userData.user_id;
      data.updatedBy = userData.user_id;
      preferenceResponseData = await SavePreference(data, token);
    } else if (buttonText === 'Update') {
      // update api calls
      delete data['isDefault'];
      data.id = updatePreferenceId;
      data.updatedBy = userData.user_id;
      preferenceResponseData = await UpdatePreference(data, token);
    }

    if (preferenceResponseData.status == 1) {
      SuccessToast(preferenceResponseData.message);
      reset(defaultValueForPreference);
      handlePreferenceHandler();
    } else {
      reset(defaultValueForPreference);
      ErrorToast(preferenceResponseData.message);
    }
  };

  //   reset button handler
  const onClickResetHandler = () => {
    reset(defaultValueForPreference);
    submitButtonRef.current.innerText = 'Save';
    setShowDefault(true);
  };

  const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        className='text-center'
        onClick={() => onClickUpdateHandler(row)}
        style={{ cursor: 'pointer' }}
      >
        <FiEdit size={17} color='#2d63a7' />
      </div>
    );
  };
  // props for preference form
  const propsForPreferenceForm = {
    register: register,
    errors: errors,
    handleSubmit: handleSubmit,
    getValues: getValues,
    watch: watch,
    reset: reset,
    onClickSubmitButtonHandler: onClickSubmitButtonHandler,
    showDefault: showDefault,
  };

  // Formatter for action
  const serialNumberFormatter = (cell, row, rowIndex, formatExtraData) => {
    return <div className='d-flex justify-content-start'>{rowIndex + 1}</div>;
  };

  const activeColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className='d-flex justify-content-center align-items-center mt-2'>
        {row.is_default ? (
          <img src={ActiveIcon} alt='active' height={12} />
        ) : (
          <img src={InactiveIcon} alt='inactive' height={12} />
        )}
        <CustomButton
          style={row.is_default ? activeButtonStyle : inactiveButtonStyle}
          // handleClick={(event) =>
          //   handleClickActiveButtonHandler(event, row.user_id)
          // }
        >
          {row.is_default ? 'Active' : 'Inactive'}
        </CustomButton>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'sl.no',
      text: 'Sl No.',
      headerStyle: () => {
        return { width: '10px', textAlign: 'left' };
      },
      csvFormatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      formatter: serialNumberFormatter,
    },
    {
      dataField: 'custodian_name',
      text: 'Name',
    },
    {
      dataField: 'desg_id',
      text: 'Custodian Designation',
    },
    {
      dataField: 'custodian_office_number',
      text: 'Office Number',
    },
    {
      dataField: 'custodian_email',
      text: 'Email',
    },
    {
      dataField: 'asset_pincode',
      text: 'Pincode',
    },
    {
      dataField: 'custodian_address',
      text: 'Custodian Address',
    },
    {
      dataField: 'asset_physical_address',
      text: 'Asset Address',
    },
    {
      dataField: 'action',
      text: 'Default Status',
      isDummyField: true,
      headerStyle: (colum, colIndex) => {
        return { width: '110px', textAlign: 'center' };
      },
      formatter: activeColumnFormatter,
      isDummyField: true,
    },
    {
      dataField: 'action',
      text: 'Actions',
      headerStyle: (colum, colIndex) => {
        return { width: '20px', textAlign: 'left' };
      },
      formatter: actionsFormatter,
      isDummyField: true,
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        backdrop='static'
        keyboard={false}
        dialogClassName='modal-width-dispose-preference'
      >
        <Modal.Header closeButton className='modal-header-section'>
          <Modal.Title
            style={{
              fontSize: '15px',
              color: '#0f3880',
              fontFamily: 'Roboto',
            }}
          >
            Preference Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PreferenceForm {...propsForPreferenceForm} />
        </Modal.Body>
        <Modal.Footer className='text-center justify-content-right'>
          <Button
            variant='primary'
            style={submitButtonStyle}
            ref={submitButtonRef}
            onClick={handleSubmit(onClickSubmitButtonHandler)}
          >
            Save
          </Button>
          <Button
            variant='primary'
            style={resetButtonStyle}
            onClick={onClickResetHandler}
          >
            Reset
          </Button>
        </Modal.Footer>
        <div className='p-3'>
          <BootstrapTable
            keyField='id'
            data={preferenceList}
            columns={columns}
          />
        </div>
      </Modal>
    </>
  );
};;;

export default PreferenceModal;
