import { axiosConfig } from '../../utilities/axios.config';
import axios from 'axios';

// Approve status data fetch
export const DepartmentDrilldown = async (data, token) => {
  var config = axiosConfig(token);

  return await axios
    .post('/reports/classCatWiseAssetCount', data, config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
