import React from 'react';
import Highcharts from 'highcharts';
import { useRecoilState } from 'recoil';
import { Row, Col } from 'react-bootstrap';
import HighchartsReact from 'highcharts-react-official';
import GisMap from './GisMap';
import SidebarHeader from '../Sidebar/SidebarHeader';
import { dataForPiechart, dataForBarGraph } from './jsonDataForChart';
import { assetCountOnFilterAtom } from '../../../recoil/atom/GIS Dashboard/assetCountOnFilter.atom';
import { ContainerVisibilityStatusAtom } from '../../../recoil/atom/GIS Dashboard/ContainerVisibilityStatus.atom';

const GISDashboardLayout = (props) => {
  const handleToggleSidebar = props;
  const [assetCountOnFilter, setAssetCountOnFilter] = useRecoilState(
    assetCountOnFilterAtom
  );

  const [containerVisibilityStatus, setContainerVisibilityStatus] =
    useRecoilState(ContainerVisibilityStatusAtom);

  return (
    <main style={{ paddingRight: 0, overflow: 'hidden' }}>
      {/* Sidebar header */}
      <SidebarHeader handleToggleSidebar={handleToggleSidebar} />

      <Row className='ms-1'>
        <Col
          xs={containerVisibilityStatus ? 12 : 7}
          style={{ paddingRight: containerVisibilityStatus && '22px' }}
        >
          {/* Map section */}
          <div className='gis-map-section' style={{ width: '100%' }}>
            <GisMap />
          </div>
        </Col>
        <Col
          xs={containerVisibilityStatus ? 0 : 5}
          style={{ display: containerVisibilityStatus && 'none' }}
        >
          <Row className='mb-2'>
            {/* Highcharts with drilldown */}
            <Col xs={12} className='ps-0'>
              <div className='gis-map-section me-3'>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={dataForBarGraph}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {/* Piechart for class wise */}
            <Col xs={4}>
              <Row className='h-50 pb-2'>
                <div className='d-flex outline division-asset-count'>
                  <div className='count-header'>Total Asset</div>
                  <div className='display-2'>{assetCountOnFilter}</div>
                </div>
              </Row>
              <Row className='h-50'>
                <div className='d-flex outline division-asset-count flex-column'>
                  <div className='count-header text-center'>
                    Total Geotagged
                  </div>
                  <div className='display-2'>{assetCountOnFilter}</div>
                </div>
              </Row>
            </Col>

            <Col xs={8}>
              <div className='gis-map-section me-3'>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={dataForPiechart}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default GISDashboardLayout;
