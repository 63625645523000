import axios from 'axios';
import { axiosConfig } from '../utilities/axios.config';

// User listing
export const UserListing = async (token, formData) => {
  var config = axiosConfig(token);
  return await axios
    .post('/users', formData, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// User listing
export const UserFilterListing = async (data, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/users', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// User Data fetching
export const UserDataFetch = async (data, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/users', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Check email exist or not
export const CheckEmailExistence = async (email, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/users/checkExistEmail', email, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Check username exist or not
export const CheckUsernameExistence = async (username, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/users/checkExistUsername', username, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Role listing api
export const RoleListing = async (token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/roles/getAllRoles', '', config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Create user profile
export const CreateUser = async (data, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/users/createUser', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// Update User Data
export const UpdateUser = async (data, userId, token) => {
  var config = axiosConfig(token);
  return await axios
    .put('/users/updateUser/' + JSON.stringify(userId), data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// jurisdiction listing
export const BoundaryListing = async (deptId, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/boundary/getBoundaryInfo', deptId, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

// DDO listing
export const DesignationListing = async (data, token) => {
  var config = axiosConfig(token);
  return await axios
    .post('/designations/getDesgByHierarchy', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
