import customAxios, { axiosConfig } from '../utilities/axios.config';

// Master Sync api call
export const MasterSync = async (token, data) => {
  var config = axiosConfig(token);
  return await customAxios
    .post('/boundary/getMasterSync', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
