import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { RiDatabaseFill } from 'react-icons/ri';
import { GiBasketballBall, GiDiscGolfBasket } from 'react-icons/gi';
import { useRecoilState } from 'recoil';
import { legendListAtom } from '../../recoil/atom/GIS Dashboard/legendList.atom';

const ListOfLegends = () => {
  const [legendList] = useRecoilState(legendListAtom);
  return (
    <>
      {legendList &&
        legendList.length > 0 &&
        legendList.map((legend) => {
          return (
            <ListGroup variant='flush'>
              <ListGroup.Item key={legend.id} className='text-left mb-1'>
                <div className='d-flex flex-column '>
                  <strong className='pb-2'>{legend.name}</strong>
                  <GiDiscGolfBasket />
                </div>
              </ListGroup.Item>
            </ListGroup>
          );
        })}
      {/* <ListGroup variant='flush'>
        <ListGroup.Item className='text-left '>
          <div className='d-flex flex-column mb-1'>
            <strong className='pb-1'>Storm Inlet</strong>
            <GiBasketballBall />
          </div>
        </ListGroup.Item>
        <ListGroup.Item className='text-left '>
          <div className='d-flex flex-column mb-1'>
            <strong className='pb-2'>Storm Inlet</strong>
            <RiDatabaseFill />
          </div>
        </ListGroup.Item>
        <ListGroup.Item className='text-left mb-1'>
          <div className='d-flex flex-column '>
            <strong className='pb-2'>Storm Inlet</strong>
            <GiDiscGolfBasket />
          </div>
        </ListGroup.Item>
      </ListGroup> */}
    </>
  );
};

export default ListOfLegends;
