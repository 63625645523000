import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { designationAtom } from '../../../recoil/atom/designationAtom';
import { contactNumberValidator } from '../../../utilities/contactNumber.validator';
import { zipcodeValidator } from '../../../utilities/zipcode.validator';

export const PreferenceForm = (props) => {
  const {
    register,
    errors,
    handleSubmit,
    onClickSubmitButtonHandler,
    showDefault,
  } = props;

  const [designation] = useRecoilState(designationAtom);

  return (
    <>
      <Form onSubmit={handleSubmit(onClickSubmitButtonHandler)}>
        <Row>
          {showDefault && (
            <Form.Group className='mb-3'>
              <Form.Check type='checkbox' id='check-api-checkbox'>
                <Form.Check.Input
                  style={{ height: '15px', width: '15px' }}
                  type='checkbox'
                  name='isDefault'
                  {...register('isDefault', {})}
                />
                <Form.Check.Label
                  className='ms-2'
                  style={{ fontSize: '15px', marginTop: '1px' }}
                >
                  Is default
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          )}

          <Form.Group as={Col} md={6} className='mb-3'>
            <Form.Label>Custodian Name</Form.Label>

            <Form.Control
              type='text'
              name='custodianName'
              {...register('custodianName', {})}
            />
          </Form.Group>

          <Form.Group as={Col} md={6} className='mb-3'>
            <Form.Label>Custodian Designation</Form.Label>

            <Form.Select
              name='desgId'
              {...register('desgId', {
                valueAsNumber: true,
              })}
              // onChange={() => handleChangeClass()}
              // ref={custodianDesignationRef}
            >
              <option value={0}>Select</option>
              {designation.length > 0 &&
                designation.map((desg) => {
                  return (
                    <option key={desg.desg_id} value={desg.desg_id}>
                      {desg.desg_name}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md={6} className='mb-3'>
            <Form.Label>Custodian Office Number</Form.Label>

            <Form.Control
              type='number'
              name='custodianOfficeNumber'
              {...register('custodianOfficeNumber', {
                valueAsNumber: true,
                validate: (value) =>
                  contactNumberValidator(value) || 'Invalid phone number!',
              })}
            />
            {errors.custodianOfficeNumber && (
              <small className='text-danger'>
                {errors.custodianOfficeNumber.message}
              </small>
            )}
          </Form.Group>

          <Form.Group as={Col} md={6} className='mb-3'>
            <Form.Label>Custodian Email</Form.Label>

            <Form.Control
              type='email'
              name='custodianEmail'
              {...register('custodianEmail', {
                pattern: /^\S+@\S+$/i,
              })}
            />
            {errors.custodianEmail && (
              <small className='text-danger'>
                Please enter an valid email address!
              </small>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Asset Pincode</Form.Label>

            <Form.Control
              type='number'
              name='assetPincode'
              {...register('assetPincode', {
                valueAsNumber: true,
                validate: (value) =>
                  zipcodeValidator(value) || 'Pincode must be 6-digit!',
              })}
            />
            {errors.pincode && (
              <small className='text-danger'>{errors.pincode.message}</small>
            )}
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Custodian Address</Form.Label>

            <Form.Control
              type='text'
              as='textarea'
              name='custodianAddress'
              {...register('custodianAddress', {})}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <Form.Label>Asset Physical Address</Form.Label>

            <Form.Control
              type='text'
              as='textarea'
              name='assetPhysicalAddress'
              {...register('assetPhysicalAddress', {})}
            />
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};
