import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Tooltip } from 'react-tippy';
import {
  BsListUl,
  BsFullscreen,
  BsFullscreenExit,
  BsChevronLeft,
} from 'react-icons/bs';
import { FiLayers } from 'react-icons/fi';
import { FaDrawPolygon } from 'react-icons/fa';
import { GiPathDistance } from 'react-icons/gi';
import { RiFilter3Fill, RiHome2Line } from 'react-icons/ri';
import { ContainerVisibilityStatusAtom } from '../../../recoil/atom/GIS Dashboard/ContainerVisibilityStatus.atom';

export const MapControlIndividual = (props) => {
  const { type, selected, handleClick, containerVisibilityStatus } = props;

  const propsForIcon = {
    size: 25,
    className: 'me-0 icons-react-list list-item',
  };
  return (
    <div
      className={
        selected === type && selected !== ''
          ? 'list-item-selected text-center d-flex justify-content-center align-items-center'
          : 'list-item text-center d-flex justify-content-center align-items-center'
      }
      title={
        type === 'Line'
          ? 'Measure Distance'
          : type === 'Polygon'
          ? 'Measure Area'
          : type
      }
      onClick={(e) => handleClick(e, type)}
      style={{
        cursor: 'pointer',
      }}
    >
      {type === 'Home' && (
        <RiHome2Line
          color={selected === 'Home' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      )}

      {type === 'Legends' && (
        <BsListUl
          color={selected === 'Legends' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      )}
      {type === 'Filter' && (
        <RiFilter3Fill
          color={selected === 'Filter' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      )}
      {type === 'Layers' && (
        <FiLayers
          color={selected === 'Layers' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      )}
      {type === 'Line' && (
        <GiPathDistance
          color={selected === 'Line' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      )}
      {type === 'Polygon' && (
        <FaDrawPolygon
          color={selected === 'Polygon' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      )}
      {type === 'Fullscreen' && containerVisibilityStatus ? (
        <BsFullscreenExit
          color={selected === 'Fullscreen' ? '#fff' : '#4187e3'}
          {...propsForIcon}
        />
      ) : (
        type === 'Fullscreen' && (
          <BsFullscreen
            color={selected === 'Fullscreen' ? '#fff' : '#4187e3'}
            {...propsForIcon}
          />
        )
      )}
    </div>
  );
};

export const MapControlIcons = (props) => {
  const { selected, handleClickControl, containerVisibilityStatus } = props;
  const propsForHome = {
    type: 'Home',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };
  const propsForLegends = {
    type: 'Legends',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };

  const propsForFilter = {
    type: 'Filter',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };

  const propsForLayers = {
    type: 'Layers',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };

  const propsForLine = {
    type: 'Line',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };
  const propsForPolygon = {
    type: 'Polygon',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };

  const propsForFullscreen = {
    type: 'Fullscreen',
    selected: selected,
    handleClick: handleClickControl,
    containerVisibilityStatus: containerVisibilityStatus,
  };

  return (
    <div className='d-flex'>
      <MapControlIndividual {...propsForHome} />
      <MapControlIndividual {...propsForLegends} />
      <MapControlIndividual {...propsForFilter} />
      <MapControlIndividual {...propsForLayers} />
      <MapControlIndividual {...propsForLine} />
      <MapControlIndividual {...propsForPolygon} />
      <MapControlIndividual {...propsForFullscreen} />
    </div>
  );
};

const MapControl = (props) => {
  const { handleClickControl, selected, onClickFullScreen } = props;

  // tooltip control
  const [trueTooltip, setTrueTooltip] = useState(true);

  const [containerVisibilityStatus, setContainerVisibilityStatus] =
    useRecoilState(ContainerVisibilityStatusAtom);

  const styleForControlContainer = {
    border: '1px solid #204d88',
    backgroundColor: '#fff',
    border: 'none',
    boxShadow: '0 2px 4px 1px rgb(0 0 0 / 14%)',
    marginBottom: '5px',
    width: '21px',
    height: '37px',
    borderRadius: '4px',
  };

  return (
    <div>
      <nav
        className='container-legend-list flex-row justify-content-between'
        style={styleForControlContainer}
      >
        <Tooltip
          theme='light'
          position='left'
          open={trueTooltip}
          html={
            <MapControlIcons
              handleClickControl={handleClickControl}
              selected={selected}
              onClickFullScreen={onClickFullScreen}
              containerVisibilityStatus={containerVisibilityStatus}
            />
          }
          duration={700}
          animation='perspective'
          distance={10}
          interactive={true}
          trigger={'click'}
          // hideOnClick={true}
          // onRequestClose={() => setTrueTooltip(!trueTooltip)}
        >
          <div
            className='d-flex flex-row align-items-center justify-content-center'
            style={{ paddingTop: '9px' }}
            onClick={() => setTrueTooltip(!trueTooltip)}
          >
            <BsChevronLeft size={18} />
          </div>
        </Tooltip>
      </nav>
    </div>
  );
};

export default MapControl;
