import React from 'react';
import { Table } from 'react-bootstrap';
import { CgProfile } from 'react-icons/cg';
import { BiBookAdd } from 'react-icons/bi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { MdLogout } from 'react-icons/md';

const HeaderOptions = (props) => {
  const {
    setOpenTooltip,
    dropdownIconStyle,
    dropDownItemStyle,
    profileHandler,
    handleChangePasswordModalOpen,
    handlePreferenceHandler,
    onConfirm,
  } = props;

  const tdStyle = {
    cursor: 'pointer',
    padding: '8px 1px 3px 7px',
  };

  return (
    <Table borderless className='p-0 m-0'>
      <tbody style={dropDownItemStyle}>
        <tr>
          <td style={tdStyle}>
            <CgProfile style={dropdownIconStyle} />
            <span
              className='ms-3'
              onClick={() => {
                setOpenTooltip(false);
                profileHandler();
              }}
            >
              Profile
            </span>
          </td>
        </tr>
        <tr>
          <td
            style={tdStyle}
            onClick={() => {
              setOpenTooltip(false);
              handleChangePasswordModalOpen();
            }}
          >
            <RiLockPasswordLine style={dropdownIconStyle} />
            <span className='ms-3'>Change Password </span>
          </td>
        </tr>
        <tr>
          <td
            style={tdStyle}
            onClick={() => {
              setOpenTooltip(false);
              handlePreferenceHandler();
            }}
          >
            <BiBookAdd style={dropdownIconStyle} />
            <span className='ms-3'>Preference</span>
          </td>
        </tr>
        <tr>
          <td
            style={tdStyle}
            onClick={() => {
              setOpenTooltip(false);
              onConfirm();
            }}
          >
            <MdLogout style={dropdownIconStyle} />
            <span className='ms-3'>Logout</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default HeaderOptions;
