// export const baseURL = process.env.REACT_APP_BASE_API;

// Rajesh
// export const baseURL = 'http://10.150.1.215:8080/api/v1';
// export const loginApi = 'http://10.150.1.215:8080/api/v1/users/login';

// AWS
// export const baseURL = 'http://3.110.108.104:8080/opams/api/v1';
export const baseURL = 'https://api.opams.in/api/v1/';
// Kishore sir
// export const baseURL = 'http://10.150.0.167:8080/api/v1';

// subhadarsini Lan
// export const baseURL = 'http://172.20.0.79:8080/api/v1'
// export const baseURL = 'http://172.31.98.154:8080/api/v1'//http://localhost:8080/api/v1'

// export const loginApi = process.env.REACT_APP_LOGIN_API;
// export const loginApi = 'http://3.110.108.104:8080/opams/api/v1/users/login';
export const loginApi = 'https://api.opams.in/api/v1/users/login';

export const assetListingApi = process.env.REACT_APP_ASSET_LISTING_API;

export const districtListingApi = process.env.REACT_APP_DISTRICT_LISTING_API;
export const blockListingApi = process.env.REACT_APP_BLOCK_LISTING_API;
export const gramListingApi = process.env.REACT_APP_GRAM_LISTING_API;
export const villageListingApi = process.env.REACT_APP_VILLAGE_LISTING_API;

export const departmentListingApi =
  process.env.REACT_APP_DEPARTMENT_LISTING_API;
export const assetClassListingApi =
  process.env.REACT_APP_ASSET_CLASS_LISTING_API;
export const assetCategoryListingApi =
  process.env.REACT_APP_ASSET_CATEGORY_LISTING_API;
export const assetCodeApi = process.env.REACT_APP_ASSET_CODE_API;

export const filterAssetApi = process.env.REACT_APP_ASSET_CODE_API;
export const googleSatelliteURL = process.env.REACT_APP_GOOGLESATELLITE_URL;
export const googleRoadNamesURL = process.env.REACT_APP_GOOGLE_ROAD_NAMES_API;
export const googleRoadMapURL = process.env.REACT_APP_GOOGLE_ROAD_MAP_API;
export const assetLayerGeoServerURL = process.env.REACT_APP_ASSET_LAYER_API;
export const terrainImageUrl = process.env.REACT_APP_TERRAIN_ICON_URL;
export const roadImageUrl = process.env.REACT_APP_ROAD_ICON_URL;

//Ec2
// export const baseURL='http://ec2-3-110-108-104.ap-south-1.compute.amazonaws.com/opams/api/v1'
