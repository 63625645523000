import axios from "axios";
import { axiosConfig } from "../../utilities/axios.config";

export const MapFilter = async (token, data) => {
  var config = axiosConfig(token);

  return await axios
    .post('/map/assetInfoWithGeoJson', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
