import { axiosConfig } from '../utilities/axios.config';
import axios from 'axios';

// Asset Image Listing
export const ChangePassword = async (data, token) => {
  var config = axiosConfig(token);

  return await axios
    .post('/users/changePassword', data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};
