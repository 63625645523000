import React from 'react';
import { Helmet } from 'react-helmet';
import BusinessLogic from '../../Dashboard/Sidebar/BusinessLogic';
import Sidebar from '../Sidebar/Sidebar';
import GISDashboardLayout from './GISDashboardLayout';
import './style.css';

const GisDashboard = () => {
  const { toggled, collapsed, handleCollapsedChange, handleToggleSidebar } =
    BusinessLogic();

  return (
    <div
      className={`app ${toggled ? 'toggled' : ''}`}
      style={{ backgroundColor: '#e7e7e7' }}
    >
      <Helmet>
        <title>GIS Dashboard | OPAMS</title>
        <meta name='description' content='gis dashboard' />
      </Helmet>

      {/* Sidebar */}
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />

      {/* Gis Dashboard layout */}
      <GISDashboardLayout
        toggled={toggled}
        collapsed={collapsed}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />

     
    </div>
  );
};

export default GisDashboard;
