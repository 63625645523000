import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ToIndianCurrency } from '../../../utilities/rupee.formatter';
import './styleForMultiSectionBargraph.css';

// drilldown(Highcharts);

const MultiSectionBargraph = (props) => {
  const { seriesData, titleText, subtitleText, yAxisText, categories } = props;

  const data = {
    chart: {
      type: 'column',
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: subtitleText,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: categories,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisText,
      },
    },

    tooltip: {
      formatter: function () {
        var formatStr = '';

        for (var i = 0; i < this.points.length; i++) {
          var point = this.points[i];

          formatStr += `<span style="color:${point.color}">● </span>
          <span style="font-size: 14px">${
            point.series.name
          } :  ${ToIndianCurrency(point.y)}</span><br/>`;
        }
        return formatStr;
      },
      shadow: true,
      itemMarginBottom: 6,
      padding: 10,
      style: {
        color: '#333333',
        fontSize: '15px',
        fontWeight: '400',
        fontFamily: 'Montserrat',
      },
      shared: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: seriesData,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={data} />
    </div>
  );
}

export default MultiSectionBargraph;
